import { tryoutService } from '../_services'
import Vue from 'vue'

function initialiseState(){
    return {
        status: {},
        list: [],
        data: {},
        meta: {},
        error: null,
        internal: {},
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    async cloneTryout({commit}, payload){
        try{
            commit('cloneTryoutRequest')
            const response = await tryoutService.cloneTryout(payload)
            commit('cloneTryoutSuccess', response.tryout)
        } catch(error) {
            commit('cloneTryoutFailure', error)
            throw new Error({message: 'Clone Operation Failed', error})
        }
    },
    async loadCollectDataBundle({commit}, payload){
        try {
            commit('loadCollectDataBundleRequest')
            const response = await tryoutService.loadDataCollectBundle(payload)
            commit('loadCollectDataBundleSuccess', payload)
            return response
        } catch (error) {
            commit('loadCollectDataBundleFailure')
        }
    },
    async loadTryoutForEvent({commit}, payload) {
        try{
            commit('loadTryoutRequest')
            const tryout = await tryoutService.findOne(payload.eventId)
            commit('loadTryoutSuccess', tryout)
            return tryout
        } catch(error) {
            commit('loadTryoutFailure', error)
        }
    },
    //**** RETIIRE */
    async loadTryout({commit}, id) {
        try{
            commit('loadTryoutRequest')
            const tryout = await tryoutService.findOne(id)
            commit('loadTryoutSuccess', tryout)
            return tryout
        } catch(error) {
            commit('loadTryoutFailure', error)
        }
    },
    async clearTryout({dispatch}, id) {
        try{
            await tryoutService.clearTryout(id)
            dispatch('alert/success','Tryout data cleared', {root:true})
        } catch(error) {
            console.error(error)
            dispatch('alert/error','Tryout data may not have been cleared properly. Reload your browser and check all data before proceding', {root:true})
        }
    },
    async findActive({commit}, clubId) {
        try{
            commit('findAllRequest')
            const response = await tryoutService.findActive(clubId)
            commit('findAllSuccess', response)
            return response
        } catch(error) {
            commit('findAllFailure', error)
        }
    },
    async findAll({dispatch, commit}) {
        try{
            commit('findAllRequest')
            const response = await tryoutService.findAll()
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async loadAllTryoutsForSeason({dispatch, commit}, seasonId){
        try{
            commit('findAllForSeasonRequest')
            const response = await tryoutService.findAllForSeason(seasonId)
            commit('findAllForSeasonSuccess', response)
            return response
        }catch(error) {
            commit('findAllForSeasonFailure')
            dispatch('alert/error', error, {root: true})
        }
    },
    /******* */
    async findAllForSeason({dispatch, commit}, seasonId){
        try{
            commit('findAllForSeasonRequest')
            const response = await tryoutService.findAllForSeason(seasonId)
            commit('findAllForSeasonSuccess', response)
            return response
        }catch(error) {
            commit('findAllForSeasonFailure')
            dispatch('alert/error', error, {root: true})
        }
    },
    async findAllForClub({commit}, payload){
        try{
            commit('findAllForClubsRequest')
            const response = await tryoutService.findAllForClub({clubId: payload.clubId})
            commit('findAllForClubSuccess', response)
        } catch(error) {
            commit('findAllForClubFailure', error)
        }
    },
    async addTryout({dispatch, commit}, tryout) {
        try{
            commit('addTryoutRequest')
            const response = await tryoutService.addTryout(tryout)
            commit('addTryoutSuccess',response.tryout)
            // Display a success message after the route change completes
            dispatch('alert/success', 'Your new tryout has been created.', {root: true})
        } catch(error) {
            commit('addTryoutFailure',error)
            // Display a success message after the route change completes
            dispatch('alert/error', error, {root: true})
        }
    },
    async updateTryout({dispatch, commit}, tryout) {
        try{
            commit('updateTryoutRequest')
            const response = await tryoutService.updateTryout(tryout)
            commit('updateTryoutSuccess', response)
        } catch(error) {
            commit('updateTryoutFailure', error)
            dispatch('alert/error', error, {root: true})
        }
    },
    async deleteTryout({commit}, payload) {
        try{
            commit('deleteTryoutRequest', payload.eventId)
            const response = await tryoutService.deleteTryout({eventId: payload.eventId})
            commit('deleteTryoutSuccess', response)
        } catch(error) {
            commit('deleteTryoutFailure', error)
            throw new Error({message: 'tryout delete failed'})
        }
    },
    async update({commit},payload) {
        commit('update', payload)
    }
}
function extractDateAndTimes(tryout) {
    
    const dateElements = {
        startDate:      new Date(tryout.startDate),
        endDate:        new Date(tryout.endDate),
        offerDeadline:  new Date(tryout.offerDeadline),
    }

    return (dateElements)
}

function addToLists(tryout) {
    const id = tryout.id

    state.list.push(id)
    Vue.set(state.data, id, tryout)
    Vue.set(state.meta, id, {
        delete: false,
        showStart: false,
        showEnd: false,
        showOffer: false,
        ...extractDateAndTimes(tryout)
    })
}

const mutations = {
    purgeData(state){
        const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },

    loadCollectDataBundleRequest(){


    },
    loadCollectDataBundleSuccess(state, payload){
        state.internal.payload = payload
    },
    loadCollectDataBundleFailure(state, error){
        state.error = error
    },





    deleteTryoutRequest(state, eventId){
        state.status={deleting: true}
        state.error=null
        state.internal.id = eventId
    },
    deleteTryoutSuccess(state,response){
        state.status={}
        Vue.delete(state.data,state.internal.id)
        Vue.delete(state.meta,state.internal.id)

        const index = state.list.indexOf(state.internal.id)
        state.list.splice(index,1)
        state.internal={response}
    },
    deleteTryoutFailure(state, error){
        state.error=error
        state.status={}
    },

    cloneTryoutRequest(state) {
        state.status = {cloning: true}
        state.error={}
    },
    cloneTryoutSuccess(state, tryout){
        addToLists(tryout)
        state.error=null
        state.status={}
    },
    cloneTryoutFailure(state, error){
        state.error=error
            state.status={}
    },



    addTryoutRequest(state) {
        state.status={adding: true}
        state.error=null
    },
    //eslint-disable-next-line
    addTryoutSuccess(state, tryout) {
        addToLists(tryout)
        state.tryout = tryout
        state.error=null
        state.status = {added: true, addedId: tryout.id}
    },
    addTryoutFailure(state, error) {
        state.error = error
        state.status = {}
    },
    findAllRequest(state) {
        state.status = {loading: true}
        state.error=null
    },
    findAllSuccess(state, tryouts) {
        state.list=[]
        state.data={}
        state.meta={}

        for(var idx in tryouts) {
            addToLists(tryouts[idx])
        }
        state.status = {}
    },
    findAllFailure(state, error) {
        state.status = {}
        state.error = error
    },

    findAllForSeasonRequest(state) {
        state.status = {loading: true}
        state.error=null
    },
    findAllForSeasonSuccess(state, tryouts) {
        state.list=[]
        state.data={}
        state.meta={}

        for(var idx in tryouts) {
            addToLists(tryouts[idx])
        }

        state.status = {}
    },
    findAllForSeasonFailure(state, error) {
        state.status = {}
        state.error = error
    },


    findAllForClubRequest(state){
        state.status={loading:true}
        state.error=null
    },
    findAllForClubSuccess(state, payload){
        state.list=[]
        state.data={}
        state.meta={}

        for(var idx in payload.data.result){
            addToLists(payload.data.result[idx])
        }
        state.status={}
    },
    findAllForClubFailure(error){
        state.error=error
        state.status={}
    },

    //------ UPDATE -------\
    updateTryoutRequest(state) {
//        state.status={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateTryoutSuccess(state, session){
//        state.status={}
        state.error=null
    },
    updateTryoutFailure(state, error) {
        state.status={}
        state.error=error
    },

    // ------------- loadTryout ------------- \
    
    loadTryoutRequest(state) {
        state.status={loading: true}
        state.error=null
    },
    loadTryoutSuccess(state, tryout) {
        state.data={}
        state.meta={}
        state.list=[]
        addToLists(tryout)
        state.status={}
    },
    loadTryoutFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------------ update data -------- \\
    update(state, payload) {
        const metaFields="startDate endDate offerDeadline delete"

        const {field, id, value} = payload
        if(metaFields.includes(field)){
            state.meta[id][field]=value
            switch (field) {
                case 'startDate': {
                    // Update the date and times in the data object too
                    state.data[id].startDate = new Date(value + "T00:01:00.000")
                    break;
                }
                case 'endDate': {
                    state.data[id].endDate = new Date(value + "T23:59:00.000") 
                    break;
                }
                case 'offerDeadline': {
                    state.data[id].offerDeadline = new Date(value + "T23:59:00.000") // MAke the date evaluation value up until just before midnight
                    break;
                }
            }
        } else {
            state.data[id][field]=value
        }
    }

}

export const tryoutStore = {
    namespaced: true,
    state,
    actions,
    mutations
}