<template>
    <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate>
            <div :class="{strike: meta[activityId].delete}">
                <span class="actNum">Activity {{ activities[activityId].order+1 }}: </span><span>{{activities[activityId].name  }}</span><span style="margin-left:20px;font-size:x-small"> ({{ activities[activityId].duration }} mins)</span>
            </div>
            <template v-slot:actions>
                <v-btn @click.native.stop 
                    v-if="false&&changes" 
                    x-small 
                    v-on:click="saveDetails" 
                    depressed 
                    color="primary" 
                    class="save-btn"
                >
                    Save
                </v-btn>
                <v-dialog v-model="confirmActivityDeleteDialog" max-width="60%">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon small color="red" v-on="on" v-bind="attrs">mdi-delete</v-icon>
                    </template>
                    <v-card>
                        <v-card-title>Confirm Activity Deletion</v-card-title>
                        <v-card-text>
                            You cannot undo this operation. All information for this activity will be deleted permanently. Are you sure you want to continue?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text @click="confirmActivityDeleteDialog=false">Close</v-btn>
                            <v-btn color="red darken-1" text @click="confirmedDeleteActivity">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-icon>mdi-chevron-down </v-icon>
            </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
            <v-row>
                <v-col cols="6">
                    <v-text-field v-model="name" label="Activity Name" />
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="duration" label="Duration" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-select 
                        v-if="(userLicense.activityTemplates || clubLicense.activityTemplates)"
                        label="Template Visibility"
                        :items="templateTypes"
                        dense
                        v-model="templateVisibility"
                        item-text="name"
                        item-value="value"
                    />
                    <v-select
                        v-else
                        label="Template Visibility (Not available for your plan)"
                        :items="['Upgrade your plan today to start using activity templates']"
                        dense
                    />

                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field v-model="objective" label="Objective"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-left">
                    <span class="text-h7 text-left" >Description</span>
                    <vue-editor ref="editor" v-model="description" 
                        :editorOptions="editorSettings" 
                        :customModules="customModulesForEditor" 
                        use-custom-image-handler
                        @image-removed="handleImageRemoved"
                        @image-added="handleImageAdded"
                        width="100%" />
                </v-col>
            </v-row>
            <div v-if="account.user.details.beta" style="padding-top:30px;padding-bottom:10px;"> 
                <v-row>
                    <v-divider/>
                </v-row>
                <v-row>
                    <span class="text-h6">Activity Groups</span>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        Team Legend:
                        <v-row>
                            <v-col cols="3" v-for="teamId in activeTeamList" :key="teamId" style="display:flex;flex-flow:row;">
                                <div :style="boxStyle(teamId)">
                                        
                                </div>
                                <div class="team-name">
                                    {{ teams[teamId].name }}

                                </div>
                            </v-col>
                            <v-col cols="3" style="display:flex;flex-flow:row;">
                                <div :style="boxStyle('Waitlist')">
                                        
                                </div>
                                <div class="team-name">
                                    Waitlist

                                </div>
                            </v-col>
                            <v-col cols="3" style="display:flex;flex-flow:row;">
                                <div :style="boxStyle('Discard')">
                                        
                                </div>
                                <div class="team-name">
                                    Discard

                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="2">
                        <v-dialog v-model="addGroupDialog" persistent max-width="600px">
                            <template v-slot:activator="{on, attrs}">
                                <v-btn 
                                    color="primary"
                                    v-on="on"
                                    v-bind="attrs"
                                    x-small
                                > 
                                    Add Group
                                </v-btn>
                            </template>
                            <ValidationObserver v-slot="{reset, handleSubmit}" ref="form">
                                <form ref="newGroup" @submit.prevent="handleSubmit(addGroup)" @reset.prevent="reset">
                                    <v-card>
                                        <v-card-title>
                                            <span class="text-h5">New Group</span>
                                        </v-card-title>
                                        <v-card-text>
                                                <v-row>
                                                    <v-col>
                                                        <ValidationProvider name="Name" rules="required" v-slot="{errors}">
                                                            <v-text-field hide-details="auto" v-model="newName" prepend-icon="mdi-rename-box" label="Group Name"/>
                                                            <div class="form-error">{{ errors[0] }}</div>
                                                        </ValidationProvider>
                                                        <v-text-field hide-details="auto" v-model="newComment" prepend-icon="mdi-rename-box" label="Comment"/>
                                                    </v-col>
                                                </v-row>
                                        </v-card-text>
                                        <v-card-actions>

                                            <v-spacer/>
                                            <v-btn text @click="closeAddGroupDialog">Close</v-btn>
                                            <v-btn color="blue darken-1" text type="submit">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </form>
                            </ValidationObserver>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-row>
                    <GroupSelectorPanel :activityId="activityId"/>
                </v-row>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {rules} from '@/_helpers/rules'
//import MiniParticipantTile from '../../components/MiniParticipantTile.vue';
import GroupSelectorPanel from '../../components/GroupSelectorPanel.vue';
import 'quill/dist/quill.core.css'
import { VueEditor} from "vue2-editor"
import { v4 as uuidv4} from 'uuid'
import ImageResize from 'quill-image-resize'
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    props: ['activityId', 'sessionId'],
    components: {
 //   MiniParticipantTile,
    GroupSelectorPanel,
    ValidationObserver,
    ValidationProvider,

    VueEditor,
},
    computed: {
        ...mapState({
            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,

            activitiesPerSession: state => state.activityStore.perSessionLists,
            activities: state => state.activityStore.data,
            activityList: state => state.activityStore.list,
            meta: state => state.activityStore.meta,

            groups: state => state.groupStore.data,
            groupList: state => state.groupStore.list,
            groupStatus: state => state.groupStore.status,
            groupsByActivity: state => state.groupStore.groupsByActivity,
            groupRoleLists: state => state.groupStore.groupRoleLists,
            availablePlayersByActivity: state => state.groupStore.availableByActivity,

            selectedTryout :    state => state.navigatorStore.selectedTryout,
            selectedClub:       state => state.navigatorStore.selectedClub,

            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,

            roles: state => state.roleStore.data,
            roleList: state => state.roleStore.list,

            account: state => state.account,

            userLicense: state => state.licenseStore.data.user,
            clubLicense: state => state.licenseStore.data.club,

            teams:      state => state.teamStore.data,
            teamList:   state => state.teamStore.list,
        }),
        
        groupList: {
            get() {
                return this.groupsByActivity[this.activityId]
            },
        },
        flagToDelete: {
            get() {
                return this.meta[this.activityId].delete
            },
            set(value) {
                this.flagChanges()
                return this.updateActivityProp({field: 'delete', id: this.activityId, value})
            }
        },
        name: {
            get() {
                return this.activities[this.activityId].name
            },
            set(value){
                this.flagChanges()
                this.updateActivityProp({field: 'name', id: this.activityId, value: value})
            }
        },
        duration: {
            get() {
                return this.activities[this.activityId].duration
            },
            set(value){
                this.flagChanges()
                this.updateActivityProp({field: 'duration', id: this.activityId, value})
            }
        },
        objective: {
            get() {
                return this.activities[this.activityId].objective
            },
            set(value){
                this.flagChanges()
                this.updateActivityProp({field: 'objective', id: this.activityId, value})
            }
        },
        templateVisibility: {
            get() {
                return this.activities[this.activityId].templatevisibility
            },
            set(value){
                this.flagChanges()
                this.updateActivityProp({field: 'templatevisibility', id: this.activityId, value})
            }
        },
        description: {
            get() {
                return this.activities[this.activityId].description
            },
            set(value){
                this.flagChanges()
                this.updateActivityProp({field: 'description', id: this.activityId, value})
            }
        },
        templateTypes: {
            get(){
                return [
                    {name:'Not a template', value: null},
                    {name:'Visibile to you',value:'USER',},
                    {name:'Visible to anyone in your club',value: 'CLUB'},
                    {name:'Visible to the public',value: 'PUBLIC'},
                ]
            }
        },
        activeTeamList(){
            const newTeamList = this.teamList.filter((teamId)=>{return this.teams[teamId].selected})
            return newTeamList
        },
    },
    methods: {
        ...mapActions('activityStore', {
            updateActivityProp: 'propertyUpdate', 
            updateActivity: 'update',
            deleteActivity: 'delete',
        }),
        ...mapActions('groupStore', {
            createGroup: 'create',
            findAllGroupsForEvent: 'findAllForEvent',
            addPlayerToGroup: 'addPlayer',
            movePlayerToGroup: 'movePlayer',
            removePlayerFromGroup: 'removePlayer',
            resetAvailablePlayers: 'resetAvailablePlayers',
            sortAvailablePlayers: 'sortAvailablePlayers',
            deleteGroupById: 'deleteGroupById',

        }),

        ...mapActions('awsStore', {
            deleteAsset: 'deleteAsset'
        }),
        boxStyle(teamId){
            if(teamId==='Discard') return `width:20px;height:20px;background: red;border-color:black;border-style:solid`
            if(teamId==='Waitlist') return `width:20px;height:20px;background: white;border-color:black;border-style:solid`
            return `width:20px;height:20px;background: ${this.teams[teamId].color};border-color:black;border-style:solid`
        },
        confirmedDeleteActivity(){
           
           this.deleteActivity({activityId: this.activityId})
           this.confirmActivityDeleteDialog=false
            this.$store.dispatch('alert/success', 'Tryout updated', {root: true})
        },
        flagChanges(){
            this.changes=true
            //this.$root.$emit("settings-activity-changes-made", this.componentId)
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        groupSize(groupId) {
            var size=0;       

            for(var roleIdx in this.roleList){
                if(Object.prototype.hasOwnProperty.call(this.groupRoleLists, groupId)){
                    if(Object.prototype.hasOwnProperty.call(this.groupRoleLists[groupId], this.roleList[roleIdx])) 
                        size+=this.groupRoleLists[groupId][this.roleList[roleIdx]].length
                }
            }
            return size
        },

        rolePlayerList(groupId, roleId){
            if(Object.prototype.hasOwnProperty.call(this.groupRoleLists, groupId)){
                if(Object.prototype.hasOwnProperty.call(this.groupRoleLists[groupId], roleId)){
                    return this.groupRoleLists[groupId][roleId]
                }
            }
            return []
        },
        flagForDeletion: function() {
            this.flagToDelete=true
        },
        unflagForDeletion: function(){
            this.flagToDelete=false
        },
        deleteGroup: async function(groupId){
            // First remove any players who have been assigned to this group
            if(Object.prototype.hasOwnProperty.call(this.groupRoleLists, groupId)){

                for(var roleId in this.groupRoleLists[groupId]){
                        for(var participantIdx in this.groupRoleLists[groupId][roleId]){
                            const participantId=this.groupRoleLists[groupId][roleId][participantIdx]
                            await this.movePlayerToGroup({
                                activityId: this.activityId,
                                fromGroupId: groupId,
                                fromRoleId: roleId,
                                toGroupId: 0,
                                toRoleId: 0,
                                participantId: participantId,
                                eventId: this.selectedTryout,
                            })
                            await this.sortAvailablePlayers({activityId: this.activityId, participants: this.participants})
                        }
                }
            }
            this.deleteGroupById(groupId) 
            //this.$emit('delete')
        },
        closeAddGroupDialog(){
            this.newName=''
            this.newComment=''
            this.addGroupDialog=false
            this.$refs.form.reset()
        },
        async addGroup(){
            await this.createGroup({
                name: this.newName,
                comment: this.newComment,
                eventId: this.selectedTryout,
                activityId: this.activityId,
                sessionId: this.sessionId,
            })
            this.newName=''
            this.newComment=''
            this.addGroupDialog=false
        },
        startDrag(evt, payload ){
            const {participantId, fromGroupId, fromRoleId} = payload
            evt.dataTransfer.dropEffect="move"
            evt.dataTransfer.effectAllowed="move"   
            evt.dataTransfer.setData('participantId', participantId)
            evt.dataTransfer.setData('fromGroupId', fromGroupId)
            evt.dataTransfer.setData('fromRoleId', fromRoleId)
        },
        async onDrop(evt, groupId, roleId){
            const participantId=evt.dataTransfer.getData('participantId')
            const fromGroupId=evt.dataTransfer.getData('fromGroupId')
            const fromRoleId=evt.dataTransfer.getData('fromRoleId')
            await this.movePlayerToGroup({
                activityId: this.activityId, 
                toGroupId: groupId,
                toRoleId: roleId,
                fromGroupId: fromGroupId,
                fromRoleId: fromRoleId,
                participantId,
                eventId: this.selectedTryout,
            })
            await this.resetAvailablePlayers({participantList: this.participantList, roleList: this.roleList, activityList: this.activityList})
            await this.sortAvailablePlayers({activityId: this.activityId, participants: this.participants})

        },
        async handleImageRemoved(image){
            this.deleteAsset({key:image, clubId: this.selectedClub, eventId: this.selectedTryout}) 
        },
        async handleImageAdded(image){
            const self=this

            var file = image
            const maxFileSize=1000000
            var filesize=file.size
            if(filesize > maxFileSize){
                this.$store.dispatch('alert/error', 'Your file is too large (Must be less than 1MB)', {root: true})
                return
            }

            // OK Throw it up into S3

            const apiUrl = process.env.VUE_APP_CSAPI
            const localUser = JSON.parse(localStorage.getItem('user'))
            const url = new URL(`${apiUrl}/aws/uploadPath`)

            const ext = file.name.substring(file.name.lastIndexOf('.')+1, file.name.length)
            url.searchParams.append('type', `Upload Activity Diagram`)
            url.searchParams.append('filename', `${file.name}`)
            url.searchParams.append('clubId', this.selectedClub)


            
            const signedURL = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer '+localUser.token,
                }
            }).
            then(
                response => {
                    return response.json()
                },
                error => {
                    console.error(`Error: ${error}`)
                })

            if(signedURL.data && signedURL.data.code) {
                if(signedURL.data.code===1010) {
                    this.$store.dispatch('alert/error', 'Your licence does not permit you to upload any more activity diagrams.', {root: true})
                }
            }
            // Let's throw the file into AWS
            const result = await fetch(signedURL.uploadURL, {
                method: 'PUT',
                headers: {
                    'Origin': 'https://www.coachsight.net',
                },
                body: file
            })
            .then(response => {
                return response
            })

            if(result.status == '200'){
                this.$store.dispatch('alert/success', 'Your activity diagram has been uploaded', {root: true})
                const element = self.$refs.editor
                if(!element){
                    console.error('COULDN"T FIND THE EDITOR')
                    
                } else {
                    self.$refs.editor.quill.focus()
                    const range = element.quill.getSelection()
                    const imageURL = `${process.env.VUE_APP_CS_S3}/userimages/activityDiagrams/${this.selectedClub}/${signedURL.fileuuid}.${ext}`
                    self.$refs.editor.quill.insertEmbed(range.index,'image',imageURL)

                }


            }
        },
        async imageHandler(){
            const input = document.createElement('input')
            input.setAttribute('type','file')
            input.setAttribute('accept','image/*')
            input.click()

            input.onchange = async ()=>{
                const self=this
                var file = input.files[0]
                const maxFileSize=1000000
                var filesize=file.size
                if(filesize > maxFileSize){
                    this.$store.dispatch('alert/error', 'Your file is too large (Must be less than 1MB)', {root: true})
                    return
                }

                // OK Throw it up into S3

                const apiUrl = process.env.VUE_APP_CSAPI
                const localUser = JSON.parse(localStorage.getItem('user'))
                const url = new URL(`${apiUrl}/aws/uploadPath`)

                const ext = file.name.substring(file.name.lastIndexOf('.')+1, file.name.length)
                url.searchParams.append('type', `Upload Activity Diagram`)
                url.searchParams.append('filename', `${file.name}`)
                const signedURL = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization': 'Bearer '+localUser.token,
                    }
                }).
                then(
                    response => {
                        return response.json()
                    },
                    error => {
                        console.error(`Error: ${error}`)
                    })

                if(signedURL.code) {
                    if(signedURL.code==='1010') {
                        this.$store.dispatch('alert/error', 'Your licence does not permit you to upload any more activity diagrams.', {root: true})
                    }
                }
                // Let's throw the file into AWS
                const result = await fetch(signedURL.uploadURL, {
                    method: 'PUT',
                    headers: {
                        'Origin': 'https://www.coachsight.net',
                    },
                    body: file
                })
                .then(response => {
                    return response
                })

                if(result.status == '200'){
                    this.$store.dispatch('alert/success', 'Your activity diagram has been uploaded', {root: true})
                    const element = self.$refs.editor
                    if(!element){
                        console.error('COULDN"T FIND THE EDITOR')
                        
                    } else {
                        self.$refs.editor.quill.focus()
                        const range = element.quill.getSelection()
                        const imageURL = `https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/userimages/activityDiagrams/${this.account.user.details.id}/${signedURL.fileuuid}.${ext}`
                        self.$refs.editor.quill.insertEmbed(range.index,'image',imageURL)

                    }


                }


            }
        },
        async saveDetails(){
            this.updateActivity(this.activities[this.activityId])
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout updated', {root: true})
            this.changes=false
        }


    },
    created(){
        //this.resetAvailablePlayers({participantList: this.participantList, roleList: this.roleList, activityList: this.activityList})
        this.componentId = uuidv4()
    },
    mounted(){
        this.$root.$on('settings-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(self.changes){
                self.saveDetails()
                self.changes=false
            }
        })
    },
    data(){
        return {
            componentId: null,
            changes: false,
            rules,
            addGroupDialog: false,
            confirmActivityDeleteDialog: false,
            newName: '',
            newComment: '',
            editorSettings: {
                modules:{
                    imageResize: {},
                    divider: true, 
                    toolbar: {
                        container: [
                            [{header: []}],
                            ["bold","italic","underline",{ "color": []} ],
                            [{list: "ordered"}, {list: "bullet"}],
                            [{align: []}],
                            ["divider", "link"],
                            [{"indent": "-1"}, {'indent': '+1'}],
                            [{'script': 'sub'}, {'script':'super'}],
                            ["image"],
                            ["closeButton"],

                        ],
                        handlers: {
                            closeButton: () => {
                            },
                            image: this.imageHandler,
                        }
                    }
                }
            },
            customModulesForEditor: [
                { alias:    "imageResize", module:ImageResize }
            ]
        }
    }

}
</script>

<style scoped>

.group-box{
    margin:0px;
}
.drag-el {
    background-color: #fff;


}

.drop-zone{
    background-color: #eee ;
    margin-bottom:px;
    height: inherit;
    padding:0px;
    margin:0px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

.role-name {
    font-size:xx-small;
    margin: 0px;
    padding:0px;
}

.group-col{
    margin:0px;
}

.team-name{
    margin-left:5px;
    font-size:x-small;
    color:black;
}
</style>