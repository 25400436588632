<template>
    <BoundedColumn style="bounded-col" :borderColor="color" titleHeight="55px" :titleBackgroundColor="color" :titleColor="contrastColor(color)">
        <div slot="title" class="column-title">
            {{ title }}
            <v-spacer/>
            <div class="column-subtitle">
                {{ subtitle }}
            </div>
        </div>
        <div slot="content" class="no-boundaries">
            <div v-if="roleDivisions">
                <div v-for="roleId in roleList" :key="roleId" class="no-boundaries role-head dropZone" @drop="dropFn($event, teamId, roleId)" @dragover.prevent @dragenter.prevent>
                    <div :style="`background: ${color};color:${contrastColor(color)}`" class="role-title no-boundaries " >
                        {{ roles[roleId][divisionLabelProperty] }}
                    </div>
                    <div v-for="id in allocatedPlayers[roleId]"  :key="id" class="no-boundaries" draggable @dragstart="startDrag($event,{participantId:id,fromTeamId:teamId, fromRoleId:roleId, fromResponse: participants[id].offerResponse} )">
                        <player-select-button teamSelector :participantId="id" v-on:click.native="clickOnPlayerFn(id)" :selected="selectedPlayers.includes(id)"/>
                    </div>

                </div>

            </div>
            <div v-else>

            </div>
        </div>
    </BoundedColumn>
</template>

<script>
import BoundedColumn from '@/components/BoundedColumn'
import PlayerSelectButton from '@/components/PlayerSelectButton.vue';
import {mapState } from 'vuex'

export default{
    props: {
        color: {
            type: String,
            default: 'black'
        },
        title: {
            type: String,
            default: "title"
        },
        subtitle: {
            type: String,
            default: ''
        },
        roleDivisions: {
            type: Boolean,
            default: true
        },
        roleList: {
            default: []
        },
        dropFn: {
            default(){return}
        },
        teamId: {
            type: String,
            default: null
        },
        divisionLabelProperty: {
            type: String,
            default: 'unset'
        },
        allocatedPlayers: {
            type: Object,
            default(){return {}} 
        },
        clickOnPlayerFn: {
            default(){return}
        },
        selectedPlayers: {
            type: Array,
            default(){return []}
        },
    },
    components:{
        BoundedColumn,
        PlayerSelectButton,
    },
    computed: {
        ...mapState({
            roles:                  state => state.roleStore.data,
            participants:           state => state.participantStore.data,
        }),
        loading(){
            return false ||
                false;
        },

    },
    methods: {
        divLabel(divId){
            return this.roles[divId][this.divisionLabelProperty]
        },
        contrastColor(hex) {
            // Y = 0.2126 R + 0.7152 G + 0.0722 B
            // S = (max(R, G, B) - min(R, G, B)) / max(R, G, B)

            // The second one is the calculation from the HSV colour space.

            // If the saturation is low enough (pick any value you like; something 
            // between 0.3 and 0.5 would work fine), check the luminance; 
            // if that's > 0.5, your contrasting colour is black, else if 
            // it's < 0.5 the colour is white. For exactly Y = 0.5, both work.


            if (hex.indexOf('#') === 0) {
                hex = hex.slice(1);
            }
            if (hex.length === 3) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            const red = Number('0x'+hex[0]+hex[1])/255
            const green = Number('0x'+hex[2]+hex[3])/255
            const blue = Number('0x'+hex[4]+hex[5])/255

            const gamma = 2.2
            const L = 0.2126 * Math.pow( red, gamma ) + 0.7152 * Math.pow( green, gamma ) + 0.0722 * Math.pow( blue, gamma );
            

            const use_black = ( L > Math.pow( 0.5, gamma ) );

            return use_black?'#000000FF':'#FFFFFFFF'


        },
        startDrag(event, payload){
            const {participantId, fromTeamId, fromRoleId, fromResponse} = payload

            event.dataTransfer.dropEffect="move"
            event.dataTransfer.effectAllowed="move"   
            event.dataTransfer.setData('participantId', participantId)
            event.dataTransfer.setData('fromTeamId', fromTeamId)
            event.dataTransfer.setData('fromGroupId', fromTeamId)
            event.dataTransfer.setData('fromRoleId', fromRoleId)
            event.dataTransfer.setData('fromResponse', fromResponse )

            this.dragFormat="opacity:25%;"
        },
    },
    watch: {
        divisionObjs(){
            console.log('Change')
        }
    },
    async created(){

    },
    data(){
        return {

        }
    }

}
</script>

<style scoped>

.bounded-col {
    display: flex;
    flex-direction: column;
}

.column-title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.column-subtitle {
    font-size:x-small;
    text-align: right;
}

.role-title {
    font-size:small;
    color:white;
    text-align: left;
    margin-bottom: 5px;
    margin-left:5px;
    font-weight:600;
    font-size:x-small;
    font-style:italic;
}
</style>