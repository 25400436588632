<template>

<div v-if="!loading" style="text-align:left;padding: 0px 10px 0px 10px">
        <v-row>
            <v-col cols="12" style="text-align:left">
                <v-btn @click.native.stop 
                    v-if="changes" 
                    x-small 
                    v-on:click="saveDetails" 
                    depressed 
                    color="primary" 
                    class="save-btn"
                    style="margin-right:10px"
                >
                    Save
                </v-btn>
                    <v-btn color="primary" x-small @click="dialog=true">
                        Add new role
                    </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="11"/>
            <v-col cols="1">
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <ValidationObserver v-slot="{handleSubmit}" ref="form">
                        <form ref="newroleform" style="margin-top:0px" @submit.prevent="handleSubmit(save)" >
                            <v-card>
                                    <v-card-title>
                                        <span class="text-h5">New Role</span>
                                    </v-card-title>
                                    <v-card-text>
                                            <v-row>
                                                <v-col cols="12">
                                                    <ValidationProvider name="new name" rules="required" v-slot="{errors}">
                                                        <v-text-field v-model="rolename" prepend-icon="mdi-rename-box" label="New Role"/>
                                                        <div class="form-error">{{ errors[0] }}</div>
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                                        <v-btn color="blue darken-1" text @click="saveDetails">Save</v-btn>
                                    </v-card-actions>
                            </v-card>
                                </form>
                    </ValidationObserver>
                        
                </v-dialog>
                <v-dialog v-model="showCriterionSelector" max-width="80%" @click:outside="selectedCriterion=null;">
                    <v-card>
                        <v-card-title>
                            Select criterion to add
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item-group v-model="selectedCriterion" color="primary">
                                    <v-list-item v-for="criterionId in availableCriterionList" :key="criterionId" :value="criterionId" @click="clickCriterion(criterionId, $event)">
                                        <v-list-item-content v-if="criteria[criterionId]">
                                            <v-list-item-title v-text="criteria[criterionId].description"/>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn x-small dense color="primary" @click="addCriterion">Add</v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2" style="margin-top:0;margin-bottom:auto;">
                <v-list v-if="roleList.length>0" nav dense>
                    <v-list-item-group v-model="selectedRole" color="primary">
                        <v-list-item v-for="roleId in roleList" :key="roleId" :value="roleId">
                            <v-list-item-content>
                                <v-list-item-title style="display:flex;flex-flow:row;">
                                    {{ roles[roleId].name }}
                                    <v-spacer/>
                                    <v-icon small color="red" @click.stop="deleteRole(roleId)">mdi-delete</v-icon>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-col style="margin-top:10px;margin-bottom:auto;">
                <v-card v-if="selectedRole && selectedRole!==0 && selectedRole!=='0'">
                    <v-card-title>{{ roles[selectedRole].name }} settings</v-card-title>
                    <v-card-text>
                        <h3>Relevent Assessment Criterion</h3>
                        <v-row>
                            <v-spacer/>
                            <v-col cols="2">
                                <v-btn color="primary" x-small @click="showCriterionSelector=true">Add criterion</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <table>
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Criterion</th>
                                            <th width="50%" scope="col">Weight</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <draggable v-model="roleCriterionList" tag="tbody">
                                        <tr v-for="(idx,n) in roleCriterionList" :key="idx.criterionId">
                                            <td scope="row">{{ idx.order+1 }}</td>
                                            <td v-if="criteria[idx.criterionId]">{{ criteria[idx.criterionId].description}}</td>
                                            <td> <v-slider :value="idx.weight" max=10 min=1 step="1" thumb-label ticks @change="changeWeight(n, idx.criterionId, $event)"/> </td>
                                            <td><v-btn x-small text @click="removeCriterion(idx.criterionId)">Remove</v-btn></td>
                                            <!--
                                            <td>{{ rcLinks[selectedRole][idx].weight }}</td>
                                            -->
                                        </tr>
                                    </draggable>
                                </table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <PermanentConfirm v-if="roles[delTarget]" :show="warnDialog" :confirm="reallyDelete" :cancel="closeWarn" actionName="Delete Role" :identifier="roles[delTarget].name">
            <template v-slot:consequences>
                All assignments to this role will be lost, links to criterion for thie role will be deletes and your staff may need to reload their app for this change to take effect. 
            </template>
        </PermanentConfirm>
        <!--



        <v-dialog v-model="warnDialog" width="600px" max-width="80%">
                    <v-card>
                        <v-card-title>Confirm Team Role Deletion</v-card-title>
                        <v-card-text>
                            This action cannot be undone. All assignments to this role will be lost, links to criterion for thie role will be deletes and your staff may need to reload their app for this change to take effect. 
                            Are you sure you want to continue?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text @click="closeWarn">Close</v-btn>
                            <v-btn color="red darken-1" text @click="reallyDelete">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            -->
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import draggable from "vuedraggable"
import PermanentConfirm from '../../components/PermanentConfirm.vue'

export default {
    props: [],
    components: {
        ValidationObserver,
        ValidationProvider,
        draggable,
        PermanentConfirm
    },
    computed: {
        ...mapState({
            selectedTryout:                 state => state.navigatorStore.selectedTryout,

            roleList:                       state => state.roleStore.list,
            roles:                          state => state.roleStore.data,
            rcLinks:                        state => state.roleStore.meta.rcLinks,
            roleStatus:                     state => state.roleStore.status,
            participantList :               state => state.participantStore.list,   
            activityList:                   state => state.activityStore.list,
            criteria:                       state => state.criterionStore.data,
            criterionList:                  state => state.criterionStore.list,
            criterionStatus:                state => state.criterionStore.status,
        }),
        loading: function (){

            return false
                || this.roleStatus.loading
                || this.criterionStatus.loading
                || !this.dataReady
        },
        availableCriterionList(){
            if(this.selectedRole===0) {console.log('Selected Role not set');return []}
            //if(!this.rcLinks[this.selectedRole]) { console.log('No Links');return []}
            var list=[]
            if(this.rcLinks[this.selectedRole]) { 
                list=this.rcLinks[this.selectedRole].map((item)=>item.criterionId)
            }
            console.log('List')
            console.log(list)
            return this.criterionList.filter((item) => !list.includes(item))
        },
        roleCriterionList: {
            get(){
                return this.rcLinks[this.selectedRole]
            },
            async set(newValue){
                for(var idx in newValue){
                    const newLink = {
                        eventId: this.selectedTryout,
                        roleId: this.selectedRole,
                        criterionId: newValue[idx].criterionId,
                        order: parseInt(idx),
                        weight: newValue[idx].weight,
                    }
                    await this.linkRoleAndCriterion(newLink)
                }
            }

        },
    },
    methods: {
        ...mapActions('roleStore',{
            createRole: 'create',
            updateRole: 'update',
            updateRoleProp: 'propertyUpdate',
            loadAllRolesForEvent: 'loadAllRolesForEvent',
            delRole: 'del',
            linkRoleAndCriterion: 'linkRoleAndCriterion',
            unlinkRoleAndCriterion: 'unlinkRoleAndCriterion',
        }),
        ...mapActions('criterionStore', [
            'findAllCriteriaForEvent',
        ]),
        ...mapActions('groupStore', {
            removeRoleFromLists: 'removeRole',
        }),
        ...mapActions('groupStore',{
            movePlayerToGroup: 'movePlayer',
            resetAvailablePlayers: 'resetAvailablePlayers',
        }),
        clickCriterion(criterionId, event){

            console.log(event)
            if(event.detail===2){
                // Double click - add this one.
                this.selectedCriterion = criterionId
                this.addCriterion()
            }
        },

        async removeCriterion(criterionId){
            await this.unlinkRoleAndCriterion({eventId: this.selectedTryout, roleId: this.selectedRole, criterionId: criterionId})
        },
        async changeWeight(idx, criterionId,value){
            const newLink = {
                eventId: this.selectedTryout, 
                roleId: this.selectedRole, 
                criterionId,
                weight: value,
                order: this.rcLinks[this.selectedRole][idx].order
            }
            this.linkRoleAndCriterion(newLink)
        },
        async addCriterion(){
            // Create a link...
            await this.linkRoleAndCriterion({eventId: this.selectedTryout, roleId: this.selectedRole, criterionId:this.selectedCriterion, weight: 1, order: this.rcLinks[this.selectedRole] ? this.rcLinks[this.selectedRole].length:0})
            this.showCriterionSelector=false;
            this.selectedCriterion=null
        },
        closeWarn(){
            this.warnDialog=false
        },
        async reallyDelete(){
            this.warnDialog=false
            this.selectedRole=0
            await this.delRole({roleId: this.delTarget})

            // Also need to remove all group assignments to this role...
            await this.removeRoleFromLists({roleId: this.delTarget})

            await this.resetAvailablePlayers({participantList: this.participantList, roleList: this.roleList, activityList: this.activityList})
            this.selectedRole=this.roleList.length>0?this.roleList[0]:0
            this.delTarget=null
        },
        deleteRole(id){
            this.warnDialog=true;
            this.delTarget=id
        },
        closeDialog: function(){
            this.newName=''
            const refForm=this.$refs.newroleform
            refForm.reset()
            this.$refs.form.reset()

            this.dialog=false
        },
        async saveDetails(){
            let self=this
            try{
                const newRole ={
                    eventId: self.selectedTryout,
                    name: self.rolename
                }
                self.$refs.form.reset()
                self.dialog=false
                await self.createRole(newRole) 
            } catch (error) {
                console.error(error)
            }



        },
        onSubmit: function(){
        },
        showDeleteWarning(){
            this.showDeleteDialog=true
        }

    },
    async created(){
        this.dataReady=false

        await this.loadAllRolesForEvent({eventId:this.selectedTryout})
        await this.findAllCriteriaForEvent({eventId: this.selectedTryout})

        this.selectedRole = this.roleList[0]
        this.dataReady=true
    },
    mounted() {
        this.$root.$on('tryoutRefresh', this.refreshHandler)
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)

    },
    data() {
        return {
            dataReady: false,
            refreshHandler: async () => {
                const self=this
                self.dataReady= false
                await self.loadAllRolesForEvent({eventId:this.selectedTryout})

                self.selectedRole = self.roleList[0]
                self.dataReady= true
            },
            changes: false,
            dialog: false,
            warnDialog: false,
            rolename: '',
            delTarget: null,
            selectedRole: 0,
            list: [
                { name: "Coachability", sport: "basket" },
                { name: "Brooke", sport: "foot" },
                { name: "Courtenay", sport: "volley" },
                { name: "David", sport: "rugby" }
            ],
            dragging: false,
            showCriterionSelector: false,
            selectedCriterion: 0,

        }
    }
}
</script>

<style scoped>

.role-chip {
    background: var(--cs1-lev2) !important;
}

</style>