<template>
    <div>
        <div><span>{{ sessions[sessionId].title }}</span></div>
        <div><span style="font-size:x-small;margin-left:10px;"> (Total Duration: {{ totalDuration }} minutes)</span> </div>
        <v-divider/>
        <div><span v-if="!orderedList" style="font-size:small;padding-left:20px;"> No activities defined</span></div>
        <draggable tag="v-expansion-panels" v-model="orderedList">
            <activity-editor v-for="activityId in orderedList" :key="activityId" :activityId="activityId" :sessionId="sessionId"/>
        </draggable>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import ActivityEditor from '@/ClubPanes/NewTryoutSettingsPanels/ActivityEditor'
import draggable from 'vuedraggable'

export default {
    props: ['sessionId'],
    components: {
        draggable,
        ActivityEditor,
    },
    computed: {
        ...mapState({
            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,

            activitiesPerSession: state => state.activityStore.perSessionLists,
            activities: state => state.activityStore.data,
            activityList: state => state.activityStore.list,
        }),
        noActivities() {
            return false //this.activitiesPerSession[this.sessionId].length>0
        },
        orderedList: {
            get() {
                const lists = this.activitiesPerSession
                return lists[this.sessionId]
            },
            set(value) {
                this.updateActivityListOrder({
                    sessionId:this.sessionId, 
                    list:value
                })
                for(var idx in this.activitiesPerSession[this.sessionId]){
                    this.updateActivity(this.activities[this.activitiesPerSession[this.sessionId][idx]])
                }
            }
        },
        totalDuration(){
            var total=0
            for(var idx in this.activitiesPerSession[this.sessionId]){
                console.log(this.activities[this.activitiesPerSession[this.sessionId][idx]].duration)
                total += parseInt(this.activities[this.activitiesPerSession[this.sessionId][idx]].duration)
            }
            return total
        }
    },
    methods: {
        ...mapActions('activityStore', {
            updateActivityListOrder: 'updateListOrder',
            updateActivity: 'update'
        }),
    },
    created(){},
    data(){
        return {}
    }

}
</script>