<template>
    <v-dialog v-if="!loading" v-model="showDialog" width="70%">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" x-small dark v-bind="attrs" @click.stop v-on="on">
                View Application
            </v-btn>
        </template>
        <ReviewCard :participantId="participantId" :selectedSession="selectedSession" :closeFn="closeDialog"></ReviewCard>
    </v-dialog>
</template>

<script>

import { mapState } from 'vuex';
import ReviewCard from '@/components/ReviewCard.vue'

export default {
    props: ['participantId', 'selectedSession'],
    components: {
        ReviewCard,
    },
    computed: {
        ...mapState({
            participantStatus: state => state.participantStore.status,
            tryoutStatus: state => state.tryoutStore.status,
            navInitialising: state => state.navigatorStore.initialising,
            qresponseStatus: state => state.qresponseStore.status,
        }),
       loading: {
            get() {
                return false
                    || this.participantStatus.loading
                    || this.tryoutStatus.loading
                    || this.navInitialising
                    || this.selectedTryout===''
                    || this.qresponseStatus.loading
            }
        },
    },
    methods: {
        closeDialog(){
            this.showDialog=false
        }
    },
    created(){
    },
    data(){
        return {
            showDialog: false
        }
    }
}
</script>

<style scoped>
.info-line{
    margin: 0px 10px 0px 10px;
    padding: 0px 0px 0px 0px;
    text-align: left;
}
.info-head{
    font-family: 'Varela round';
    font-weight: 600;

}
</style>