<template>
    <loading v-if="loading"/>
    <div style="width:100%" v-else>
        <v-dialog v-model="reasonDialog" max-width="60%">
            <v-card>
                <v-card-title>Absence Record</v-card-title>
                <v-card-text>
                    <v-text-field label="Absence Reason" v-model="reason"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="blue darken-1" text @click="closeReasonDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveReason">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ValidationObserver v-slot="{reset, invalid, handleSubmit}">
            <v-dialog v-model="editParticipant" width="80%">
                <v-card>
                    <v-card-title>Edit {{ $capitalize(tryouts[selectedTryout].participantPronoun) }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6" sm="6" md="6" lg="4" xl="4" >
                                    <ValidationProvider rules="required" name = "First Name" v-slot="{errors}">
                                        <v-text-field label="First Name*" hide-details v-model="firstName"/>
                                        <div class="field-error">{{ errors[0] }}</div>
                                    </ValidationProvider>

                                </v-col>
                                <v-col cols="6" sm="6" md="6"  lg="4" xl="4">
                                    <ValidationProvider name = "Preferred Name" v-slot="{errors}">
                                        <v-text-field label="Preferred Name" hide-details v-model="preferredName" />
                                        <div class="field-error">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6" sm="6" md="6"  lg="4" xl="4">
                                    <ValidationProvider rules="required" name = "Last Name" v-slot="{errors}">
                                        <v-text-field label="Last Name*" hide-details v-model="lastName" />
                                        <div class="field-error">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" style="display:flex;flex-flow:row">
                                    <div style="padding-right:10px">{{ $capitalize(tryouts[selectedTryout].participantPronoun) }} Number</div>
                                    <v-btn color="var(--cs1-lev1)" style="color:white;font-weight:600;font-size:1.5em" @click="newNumberDialog=true">{{ participantNumber }}</v-btn> 
                                </v-col>
                                <v-col cols="12" class="text-left">
                                    <span class="text-h6">Preferred Roles</span>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="inputRole1" label="First preference" :items="roleList">
                                        <template v-slot:item="{item}">
                                            {{ roles[item].name }}
                                        </template>
                                        <template v-slot:selection="{item}">
                                            {{ roles[item].name }}
                                        </template> 
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="inputRole2" label="Second preference" :items="roleList">
                                        <template v-slot:item="{item}">
                                            {{ roles[item].name }}
                                        </template> 
                                        <template v-slot:selection="{item}">
                                            {{ roles[item].name }}
                                        </template> 
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                            <v-spacer/>
                            <v-btn color="blue darken-1" text @click="closeEditor(reset)" > Close </v-btn>
                            <v-btn :disabled="invalid" color="blue darken-1" text @click="handleSubmit(saveUserEdit);reset()" > Save </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>
        </ValidationObserver>
        <v-dialog
            v-model="newNumberDialog"
            width="80%"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5" style="word-break:break-word">Change {{ tryouts[selectedTryout].participantPronoun }} Number</span>
                </v-card-title>
                <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                Select a new number for this {{ tryouts[selectedTryout].participantPronoun }}
                            </v-col>
                            <v-col>
                                <v-text-field 
                                    v-if="false"
                                    label="New Number" 
                                    required 
                                    :rules="[rules.number]"
                                    v-model="newNumber"
                                />
                            </v-col>
                        </v-row>
                        <v-sheet>
                            <v-row >
                                <v-col cols="3" sm="3" md="3" lg="1" xl="1" v-for="i of availableNumbers" :key="i" @click="selectNum(selectedParticipant, i)" >
                                    <v-btn color="var(--cs1-lev1)" style="color:white;font-weight:600;font-size:1.5em">{{ i }}</v-btn> 
                                </v-col>
                            </v-row>

                        </v-sheet>
                                                <!--
                        <v-carousel v-if="false" :continuous="false" :cylce="false" :hide-delimiters="$vuetify.breakpoint.mdAndUp" :show-arrows="$vuetify.breakpoint.mdAndUp">
                            
                            <v-carousel-item v-for="n in availableNumberPages" :key="n" >
                                    <v-sheet
                                        height="100%"
                                        
                                        tile
                                    >
                                        <v-row >
                                            <v-col cols="4" sm="4" md="4" lg="1" xl="1" v-for="i in showPerPage" :key="i" @click="selectNum(selectedParticipant, availableNumbers[(n-1)*showPerPage+i-1])" >
                                                <v-btn v-if="(n-1)*showPerPage+i-1 < availableNumbers.length" color="var(--cs1-lev1)" style="color:white;font-weight:600;font-size:1.5em">{{ availableNumbers[(n-1)*showPerPage+i-1] }}</v-btn> 

                                                <svg v-if="(n-1)*showPerPage+i-1 < availableNumbers.length" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 80" style="cursor:pointer;enable-background:new 0 0 32 40;" xml:space="preserve">
                                                    <g id="Layer_1" >
                                                        <path style="fill:var(--cs1-lev1)" d="M27.4,15.2c-2.2,0-2.7-4.5-2.8-7.5c0-0.3,0.1-1.3,0.3-2.4c0.1-0.5,0.1-1.3-0.3-1.7c-0.4-0.5-0.9-0.7-1.4-0.7
                                                            c0,0-1.4-0.2-2.1,0.1c-0.7,0.2-1.1,1.2-1.1,1.9c0,0.3,0,0.6,0,0.9c0,3.8-1.8,7-3.9,7s-3.9-3.2-3.9-7c0-0.3,0-0.6,0-0.9
                                                            c0-0.5-0.1-1-0.5-1.4c-0.3-0.4-0.8-0.6-1.3-0.6H9.1c-0.7,0-1.4-0.2-1.9,0.9c-0.1,0.1,0,1,0,1.1c0.3,1.1,0.4,1,0.6,2.6
                                                            c0.3,2.6,0.1,7.5-3.2,8C4,15.5,4,15.8,4,16v17.4c0,0.2,0.2,0.4,0.4,0.4h23.5c0.2,0,0.4-0.2,0.4-0.4V15.6
                                                            C28.3,15.4,28,15.2,27.4,15.2z"/>
                                                        <path d="M5.1,13.3"/>
                                                        <text transform="matrix(1 0 0 1 7.6984 29.0944)" style="font-weight:600; fill:#FFFFFF;font-family:'Varela Round';font-size:'16px'">{{ availableNumbers[(n-1)*showPerPage+i-1] }}</text>
                                                    </g>
                                                </svg>
                                            </v-col>
                                        </v-row>
                                    </v-sheet>
                            </v-carousel-item>
                        </v-carousel>
                                            -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="newNumberDialog=false">Cancel</v-btn>
                </v-card-actions>
            </v-card> 
        </v-dialog>
        <ValidationObserver v-slot="{reset, handleSubmit, invalid}">
            <v-dialog v-model="showDialog" persistent max-width="600px" width="60%" >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">New {{ $capitalize(tryouts[selectedTryout].participantPronoun) }}</span> 
                    </v-card-title> 
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6" sm="6" md="6" lg="4" xl="4">
                                    <ValidationProvider rules="required" name = "First Name" v-slot="{errors}">
                                        <v-text-field label="First Name*" hide-details v-model="firstName"/>
                                        <div class="field-error">{{ errors[0] }}</div>
                                    </ValidationProvider>

                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="4" xl="4">
                                    <ValidationProvider name = "Preferred Name" v-slot="{errors}">
                                        <v-text-field label="Preferred Name" hide-details v-model="preferredName" />
                                        <div class="field-error">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="4" xl="4">
                                    <ValidationProvider rules="required" name = "Last Name" v-slot="{errors}">
                                        <v-text-field label="Last Name*" hide-details v-model="lastName" />
                                        <div class="field-error">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" class="text-left">
                                    <span class="text-h6">Preferred Roles</span>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="inputRole1" label="First preference" :items="roleList">
                                        <template v-slot:item="{item}">
                                            {{ roles[item].name }}
                                        </template>
                                        <template v-slot:selection="{item}">
                                            {{ roles[item].name }}
                                        </template> 
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="inputRole2" label="Second preference" :items="roleList">
                                        <template v-slot:item="{item}">
                                            {{ roles[item].name }}
                                        </template> 
                                        <template v-slot:selection="{item}">
                                            {{ roles[item].name }}
                                        </template> 
                                    </v-select>
                                </v-col>
                            </v-row> 
                        </v-container> 

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="blue darken-1" text @click="closeDialog(reset)" > Close </v-btn>
                        <v-btn :disabled="invalid" color="blue darken-1" text @click="handleSubmit(saveUser);reset()" > Save </v-btn>
                    </v-card-actions>
                </v-card>
                
            </v-dialog>
        </ValidationObserver>
        <v-row>
            <v-col cols="6" sm="6" md="4" lg="4" xl="4">
                <div class="pane-heading">
                    {{ $capitalize(tryouts[selectedTryout].participantPronoun) }} List
                </div>
            </v-col>
            <v-col v-responsive.xs.sm cols="6" style="text-align:right;display:flex;justify-content:right;flex-flow:column;">
                <v-btn color="primary" dark x-small @click="showDialog=true" > Add New {{ $capitalize(tryouts[selectedTryout].participantPronoun) }} </v-btn>
                <!--
                <v-btn color="primary" dark x-small @click="exportData" >Export Data</v-btn>
            -->
            </v-col>
            <v-spacer/>
            <v-col v-responsive.md.lg.xl cols="4" style="display:flex;flex-flow:row">
                <v-spacer/>
                <v-btn color="primary" dark x-small @click="showDialog=true" > Add New {{ $capitalize(tryouts[selectedTryout].participantPronoun) }} </v-btn>
                <v-btn color="primary" dark x-small style="margin-left:5px;" @click="exportData" >Export Data</v-btn>
            </v-col>

        </v-row>

        <v-row :key="updateKey">
            <v-col cols="12">
                <v-data-table
                    :headers="participantHeaders"
                    :items="participantArray"
                    :items-per-page="-1"
                    class="elevation-1"
                    fixed-header
                    height="70vh"
                    mobile-breakpoint="10" 
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :page.sync="page"
                    @click:row="clickRow"
                >
                    <template v-if="this.$vuetify.breakpoint.mdAndUp" v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer 
                        :pagination="pagination" 
                        :options="options"
                        @update:options="updateOptions"
                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                    </template>
                    <template v-slot:item.participantNumber="{item}">
                        <!-- 
                        <span :class="[item.archived?'strikeout':'']">{{ item.participantNumber }}</span>
                        -->
                        <v-btn @click.stop="selectedParticipant=item.id; newNumberDialog=true" color="primary" x-small>{{ item.participantNumber }}</v-btn>
                    </template>
                    <template v-slot:item.firstName="{item}">
                        <span :class="[item.archived?'strikeout':'']">{{ item.firstName }}</span>
                    </template>
                    <template v-slot:item.lastName="{item}">
                        <span :class="[item.archived?'strikeout':'']">{{ item.lastName }}</span>
                    </template>
                    <template v-slot:item.attended="{item}">
                        <span v-if="selectedSession===0 || selectedSession==='0'">{{ item.attended }}</span>
                        <v-checkbox v-else-if="item.attended === -1" indeterminate :disabled="enableFor(['admin','owner','support'])" @click.stop v-on:change="aToggle(item.id)"/>
                        <v-checkbox v-else v-model="item.attended" v-on:change="aToggle(item.id)" @click.stop :disabled="enableFor(['admin','owner','support'])" />
                    </template>
                    <template v-slot:item.absenceReason="{item}">
                        <span v-if="item.attended===1" :class='{strike:true}'>{{item.absenceReason}}</span>
                        <span v-else>{{item.absenceReason}}</span>
                    </template>
                    <template v-slot:item.profileImage="{item}">
                        <v-tooltip right>
                            <template v-slot:activator="{on, attrs}">
                                <v-img v-if="imageAllowed(item.id)" v-on="on" v-bind="attrs" :src="item.profileImage" max-height="20px" max-width="20px"/>
                                <v-icon v-else size="20px">mdi-account-outline</v-icon>
                            </template>
                            <span>
                                <v-img v-if="imageAllowed(item.id)" :src="item.profileImage" max-height="200px" max-width="200px"/>
                                <v-icon v-else size="200px">mdi-account-outline</v-icon>
                            </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.deskProcessed="{item}">
                        <v-checkbox :value="item.deskProcessed" v-model="item.deskProcessed" @click.stop v-on:change="pToggle(item.id)" :disabled="enableFor(['admin','owner','support'])"/>
                    </template>
                    <template v-slot:item.viewApplication="{item}">
                        <review-popup  :participantId="item.id"/>
                    </template>
                    <template v-slot:item.withdrawToggle="{item}">
                        <v-checkbox :disabled="enableFor(['admin', 'owner', 'support'])" v-model="item.archived" @click.stop v-on:change="toggle(item.id)"/>
                    </template>
                    <template v-slot:item.excludeToggle="{item}">
                        <v-checkbox :disabled="enableFor(['admin', 'owner'])" v-model="item.bypassCollection" @click.stop v-on:change="eToggle(item.id)"/>

                    </template>"
                </v-data-table>

            </v-col>
        </v-row>
  
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/loading'
import ReviewPopup from '@/components/ReviewPopup'
import { v4 as uuidv4} from 'uuid'
import { rc_participants } from '../../_helpers/responseCodes';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    props: [],
    components: {
        Loading,
        //eslint-disable-next-line
        ReviewPopup,
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            participants: state => state.participantStore.data,
            participantList: state => state.participantStore.list,
            participantStatus: state => state.participantStore.status,
            teams: state => state.teamStore.data,
            teamStatus: state => state.teamStore.status,
            tryouts: state => state.tryoutStore.data,
            tryoutList:     state => state.tryoutStore.list,
            tryoutStatus: state => state.tryoutStore.status,

            appQuestions: state => state.appquestionStore.data,
            appQuestionStatus: state => state.appquestionStore.status,

            qresponses : state => state.qresponseStore.data,
            qresponseList: state => state.qresponseStore.list,
            qresponseStatus: state => state.qresponseStore.status,

            selectedTryout: state => state.navigatorStore.selectedTryout,
            selectedSeason: state => state.navigatorStore.selectedSeason,
            selectedSession: state => state.navigatorStore.selectedSession,

            userData: state => state.userStore.all,

            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,

            attendancePerSession: state => state.attendanceStore.perSessionLists,
            attendance: state => state.attendanceStore.data,
            attendanceStatus: state => state.attendanceStore.status,

            roleList:   state => state.roleStore.list,
            roles:      state => state.roleStore.data,
        }),
        loading: function () {
            return this.preparingData
            /*
            return this.participantStatus.loading 
            || this.teamStatus.loading 
            || this.preparingData 
            || this.tryoutStatus.loading 
            || this.qresponseStatus.loading
            || this.appQuestionStatus.loading
            || this.attendanceStatus.loading
            */
        },
        alphaParticipantList: {
            get() {
                let alphaSort = this.participantList;

                alphaSort.sort((a,b) => {
                    return this.participants[a].lastName.localeCompare(this.participants[b].lastName)
                }) 
                return alphaSort
            }
        },
        numParticipantList: {
            get(){
                let numSort = this.participantList;

                numSort.sort((a,b) => {
                    return this.participants[a].participantNumber < this.participants[b].participantNumber
                })
                return numSort
            }
        },
        participantArray: function () {
            if(this.updateKey!=='111'){
                var results=[]
                for(var i=0;i<this.numParticipantList.length;i++){
                    results[i] = this.participants[this.participantList[i]]

                    if(this.selectedSession !== 0 && this.selectedSession !== '0'){
                        const attId = this.attendancePerSession[this.selectedSession][this.participantList[i]]
                        
                        if(typeof this.attendance[this.attendancePerSession[this.selectedSession][this.participantList[i]]]==="undefined"){
                            results[i].attended=false
                            results[i].absenceReason=''
                        } else {
                            results[i].attended = this.attendance[this.attendancePerSession[this.selectedSession][this.participantList[i]]].attended
                            results[i].absenceReason = this.attendance[attId].absenceReason

                        }
                    

                    } else {
                        const sessionsAttended = this.attendancePerSession[0][this.participantList[i]]
                        const sessions = Object.keys(this.attendancePerSession).length-1
                        results[i].attended=`${sessionsAttended}/${sessions}`
                        results[i].absenceReason=''
                    }
                }
                return results
            } else {
                return []
            }

        },
        sessionNameList: function() {
            const results=[{id: 0, name: 'All Sessions'}]
            for(var i=0;i<this.sessionList.length;i++){
                results.push({
                    id: this.sessionList[i],
                    name: this.sessions[this.sessionList[i]].title
                })
            }
            return results
        },
        availableNumbers(){
            const numbers=[]
            for(var num = parseInt(this.tryouts[this.selectedTryout].minParticipantNumber);num<=parseInt(this.tryouts[this.selectedTryout].maxParticipantNumber);num++){
                numbers.push(num)
            }
            for(var id of this.participantList){
                const idx = numbers.indexOf(this.participants[id].participantNumber)
                if(idx !== -1){
                    numbers.splice(idx,1)
                }
            }
            return numbers
        },
        availableNumberPages(){
            return Math.ceil(this.availableNumbers.length/this.showPerPage)
            
        },
        showPerPage(){
            var showPerPage=60
            if(this.$vuetify.breakpoint.smAndDown) showPerPage=21 
            return showPerPage
        }
    },
    methods: {
        ...mapActions('userStore', {
            manualUserCreate: 'createManual'
        }),
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
            updateParticipantProperty: 'propertyUpdate',
            updateParticipant: 'update',
            withdrawParticipant: 'archive',
            reinstateParticipant: 'unarchive',
            createManualParticipant: 'addManual',
            updateLocalParticipant: 'updateLocal',
            changeParticipantNumber: 'changeParticipantNumber',
        }),
        ...mapActions('teamStore', {
            findAllTeamsForEvent: 'findAllForEvent',
        }),
        ...mapActions('qresponseStore', {
            findAllResponsesForEvent: 'findAllForEvent',
        }),
        ...mapActions('appquestionStore', {
            findAllQuestionsForEvent: 'loadAllQuestionsForEvent'
        }),
        ...mapActions('tryoutStore', {
            getAllTryoutsForSeason: 'findAllForSeason',
        }),
        ...mapActions('sessionStore', {
            findAllSessionsForEvent: 'findAllForEvent',
        }),
        ...mapActions('attendanceStore', {
            findAllAttendanceForEvent: 'findAllForEvent',
            updateAttendanceState: 'propertyUpdate',
            updateAttendance: 'update',
            updateLocalAttendance: 'localUpdate'
        }),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent',
        ]),
        //eslint-disable-next-line
        clickRow(participant, tableRow, event){
            this.selectedParticipant=participant.id
            this.firstName=participant.firstName
            this.lastName=participant.lastName
            this.preferredName = participant.preferredName
            this.participantNumber=participant.participantNumber
            this.inputRole1 = participant.prefRole1
            this.inputRole2 = participant.prefRole2
            this.editParticipant=true
        },
        closeEditor(reset){
            reset()
            this.lastName=''
            this.firstName=''
            this.preferredName=''
            this.participantNumber=''
            this.editParticipant=false
        },
        async saveUserEdit(){
            console.log('Saving')
            await this.updateParticipantProperty({field: 'firstName', id: this.selectedParticipant, value: this.firstName})
            await this.updateParticipantProperty({field: 'lastName', id: this.selectedParticipant, value: this.lastName})
            await this.updateParticipantProperty({field: 'preferredName', id: this.selectedParticipant, value: this.preferredName})
            await this.updateParticipantProperty({field: 'prefRole1', id: this.selectedParticipant, value: this.inputRole1})
            await this.updateParticipantProperty({field: 'prefRole2', id: this.selectedParticipant, value: this.inputRole2})
            await this.updateParticipant(this.participants[this.selectedParticipant])
            this.editParticipant=false

        },
        async selectNum(participantId, num){
            const result = await this.changeParticipantNumber({
                participantId: participantId,
                participantNumber: num
            })
            const pronoun = this.$capitalize(this.tryouts[this.selectedTryout].participantPronoun)

            if(result.data.code === rc_participants.PARTICIPANT_NUMBER_CHANGED){
                this.participantNumber=num
                this.$toast.success(`${pronoun} number was successfully changed`)
            } else if(result.data.code === rc_participants.PARTICIPANT_NUMBER_USED) {
                this.$toast.error(`${pronoun} number is no longer available. Please try again with a different number.`) 
            }
            this.newNumberDialog=false
            
        },
        imageAllowed: function(playerId){
            if(playerId && playerId !== 0){
                if(this.participants[playerId].junior){
                    return this.participants[playerId].imageConsent
                } else {
                    return true
                }
            }
            return false
        },
        closeDialog(reset){
            reset()
            this.firstName=''
            this.lastName=''
            this.preferredName=''
            this.participantNumber=''
            this.email=''
            this.phone=''
            this.showDialog = false
        },
        toggle(id){
            if(this.participants[id].archived) {
                this.withdrawParticipant({participantId: id})
            } else {
                this.reinstateParticipant({participantId: id})
            }
        },
        eToggle(id){
           // this.updateParticipantProperty({field:'bypassCollection', id: id, value: !this.participants[id].bypassCollection})
            this.updateParticipant(this.participants[id])
        },
        pToggle(id){

            if(!this.participants[id].deskProcessed){
                this.updateParticipantProperty({field: "deskProcessed", id: id, value: 0}) 
            } else {
                this.updateParticipantProperty({field: "deskProcessed", id: id, value: 1}) 
            }
            this.updateParticipant(this.participants[id])
        },
        // Enable for: returns true is this user is not a member of the roles provided
        enableFor(list){
            for (var idx in list){
                if(this.tryouts[this.selectedTryout][list[idx]]) return false // Found a match, so don't disable the control
            }
            return true
        },
        aToggle(id){
            // Update the "temporary" absence indicator 

            const attId = this.attendancePerSession[this.selectedSession][id]
            if(this.attendance[attId].attended===-1){
                this.updateAttendanceState({field: "attended", id: attId, value: 1}) 
                this.updateAttendance(this.attendance[attId])
            } else if(this.attendance[attId].attended===0){
                this.updateAttendanceState({field: "attended", id: attId, value: 1}) 
                this.updateAttendance(this.attendance[attId])
            } else {
                this.updateAttendanceState({field: "attended", id: attId, value: 0}) 
               // this.updateAttendance(this.attendance[attId])
                this.reasonDialog=true 
                this.reasonId=id
                this.reason=this.attendance[attId].absenceReason
            }
        },
        closeReasonDialog(){

            this.reasonDialog=false
            this.reason=''
        },
        saveReason(){
            const attId = this.attendancePerSession[this.selectedSession][this.reasonId]
            this.updateAttendanceState({field: "absenceReason", id: attId, value: this.reason}) 
            this.updateAttendance(this.attendance[attId])
            this.reason=''
            this.reasonDialog=false
        },
        saveUser: async function() {

            this.showDialog=false
            this.email=uuidv4() + "@coachsight.net"
            this.phone="Manual Entry"

            // Check the user doesn't already exist...

            await this.manualUserCreate({
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                nickName: this.preferredName,
                phone: this.phone,
                eventId: this.selectedTryout
            })

            var createObj = {
                userId: this.userData.user.user.id, 
                firstName: this.firstName, 
                preferredName: this.preferredName, 
                lastName: this.lastName, 
                eventId: this.selectedTryout, 
                participantNumber: 0 
            }
            if(this.inputRole1){
                createObj.inputRole1 = this.inputRole1
            }
            if(this.inputRole2){
                createObj.inputRole2 = this.inputRole2
            }

            const part = await this.createManualParticipant(createObj)
            if(part.data.code === rc_participants.PARTICIPANT_FOUND){
                this.$toast.error(`A ${this.tryouts[this.selectedTryout].participantPronoun} with this name already exists. Please check that you need to add a new ${this.tryouts[this.selectedTryout].participantPronoun}`)
                this.showDialog=true
            } else {
                console.log(part.data.result)

                // Set attendance for this user

                await this.refresh()
                if(this.markAttending) {
                    const attId = this.attendancePerSession[this.selectedSession][part.data.result.id]
                    await this.updateAttendanceState({field: "attended", id: attId, value: 1}) 
                    await this.updateAttendance(this.attendance[attId])
                } 
                this.participantNumber=part.data.result.participantNumber
                this.selectedParticipant=part.data.result.id
                this.editParticipant=true
            }


            
        },
        exportData(){
            var headings=[]
            for(var idx in this.participantHeaders){
                headings.push(`${this.participantHeaders[idx].text}`)
            }
            for(idx in this.sessionList){
                headings.push(`Attendance at ${this.sessions[this.sessionList[idx]].title}`)
                headings.push(`Attendance comment for ${this.sessions[this.sessionList[idx]].title}`)
            }
            headings.push
            var rows=[]
            rows.push(headings)
            for(idx in this.participantArray){
                var row = []
                row.push( ''+this.participantArray[idx].participantNumber)
              //  row.push(this.imageAllowed(this.participantArray[idx].id)?'Y': 'N')
                row.push(this.participantArray[idx].firstName)
                row.push(this.participantArray[idx].lastName)
                row.push(this.participantArray[idx].processed?'Y':'N')
                row.push(this.participantArray[idx].attended)
                row.push(this.participantArray[idx].absenceReason)
                row.push('details online')
                if(this.participantArray[idx].offer){
                    if(this.participantArray[idx].offer === 'Discard') {
                        row.push("No Offer")
                    } else {
                        row.push(this.teams[this.participantArray[idx].offer].name)
                    }
                } else {
                    row.push("Waitlist")
                }
                row.push(this.participantArray[idx].offerResponse?this.participantArray[idx].offerResponse:'No response')
                row.push(this.participantArray[idx].archived?'Y':'N')
                row.push(this.participantArray[idx].bypassCollection?'Y':'N')


                for(var idx2 in this.sessionList){
                    var attendanceId = this.attendancePerSession[this.sessionList[idx2]][this.participantArray[idx].id]
                    switch(this.attendance[attendanceId].attended){
                        case -1: row.push('Not Recorded');row.push('');break;
                        case 0: row.push('Did not attend');row.push(this.attendance[attendanceId].absenceReason);break;
                        case 1: row.push('Attended');row.push('');break;
                    }
                }

                rows.push(row)
            }
            let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            var filename = this.tryouts[this.selectedTryout].title+".csv"
            link.setAttribute("download", filename);
            document.body.appendChild(link); // Required for FF
    
            link.click(); // This will download the data file named "my_data.csv".  
            Vue.$toast.success(`Check your downloads folder. Your data has been exported to ${filename}`)
        },
        async refresh(){
            this.preparingData=true
            await this.findAllParticipantsForEvent(this.selectedTryout)
           // await this.findAllTeamsForEvent({eventId: this.selectedTryout})
            await this.findAllResponsesForEvent(this.selectedTryout)
            await this.findAllQuestionsForEvent({eventId: this.selectedTryout})
            await this.findAllSessionsForEvent(this.selectedTryout)
            await this.findAllAttendanceForEvent({eventId: this.selectedTryout})
            await this.loadAllRolesForEvent({eventId: this.selectedTryout})
            
            if(this.tryoutList.length===0) 
                await this.getAllTryoutsForSeason(this.selectedSeason)
            this.preparingData=false
        }

    },
    async created(){
        await this.refresh()
        
        this.participantHeaders.push( {
            text: this.$capitalize(this.tryouts[this.selectedTryout].participantPronoun)+" Number",
            align: 'start',
            sortable: true,
            value: 'participantNumber',
        })
                
                //{
                 //   text: 'Profile Image',
                 //   align: 'start',
                 //   sortable: false,
                 //   value: 'profileImage'
                //},
        this.participantHeaders.push( {
            text: 'First Name',
            align: 'start',
            value: 'firstName'
        })
        this.participantHeaders.push( {
            text: 'Last Name',
            align: 'start',
            value: 'lastName',
        })
                
            
        if(this.tryouts[this.selectedTryout].owner || this.tryouts[this.selectedTryout].admin || this.tryouts[this.selectedTryout].support) {
            this.participantHeaders.push({
                text: 'Attendance',
                align: 'start',
                sortable: true,
                value: 'attended'
            })
            this.participantHeaders.push({
                text: 'Reason',
                align: 'start',
                sortable: false,
                value: 'absenceReason'

            })
            this.participantHeaders.push({
                text: 'Processed',
                align: 'start',
                sortable: true,
                value: 'deskProcessed'
            })
            this.participantHeaders.push({
                text: 'Application',
                align: 'start',
                sortable: false,
                value: 'viewApplication'
            })
            this.participantHeaders.push({
                text: 'Offer',
                align: 'start',
                value: 'teamName'
            })
            this.participantHeaders.push({
                text: 'Response',
                align: 'start',
                value: 'offerResponse'
            })
            this.participantHeaders.push({
                text: 'Withdrawn',
                align: 'start',
                value: 'withdrawToggle'
            }),
            this.participantHeaders.push({
                text: 'Exclude from Data Collection',
                align: 'start',
                value: 'excludeToggle'
            })
       }
    },
    sockets: {
        connect(){
            // Rejoin the room if we have been disconnected
            const roomId = `attendance-${this.selectedTryout}`
            this.$socket.emit('join-room', roomId, localStorage.getItem('jwt'))
            this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`, localStorage.getItem('jwt'))
        },
        'UpdatePlayerAttendance'(data){
            this.updateLocalAttendance(data)
            this.updateKey=uuidv4()
        },
        'ParticipantUpdated'(data){
            this.updateLocalParticipant(data)
            this.updateKey=uuidv4()
        },
        //eslint-disable-next-line
        async 'NewParticipant'(data){
            this.preparingData=true
            // Don't update until the attendance update comes through.
            await this.findAllAttendanceForEvent({eventId: this.selectedTryout})
            this.preparingData=false
            this.updateKey=uuidv4()
        }
    },
    mounted(){
        this.$root.$on('tryoutRefresh', this.refreshHandler)
        const roomId = `attendance-${this.selectedTryout}`
        this.$socket.emit('join-room', roomId, localStorage.getItem('jwt'))
        this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`, localStorage.getItem('jwt'))
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh', this.refreshHandler)
    },
    unmounted(){
        const roomId = `attendance-${this.selectedTryout}`
        this.$socket.emit('leave-room', roomId)
    },

    data() {
        return {
            refreshHandler: async() => {
                var self=this
                self.refresh()
            },
            markAttending: true,
            sortBy: "firstName",
            sortDesc: null,
            updateKey: null,
            reasonDialog: false,
            reason: '',
            reasonId:'',
            firstName: '',
            lastName: '',
            preferredName: '',
            email: '',
            phone: '',
            participantNumber: '',
            showDialog: false,
            newNumberDialog: false,
            newNumber:null,
            preparingData: true,
            selectedParticipant: null,
            //selectedSession: 0,
            editParticipant:false,
            participantHeaders: [],
            inputRole1:null,
            inputRole2:null,
            page:null,
            /*
                {
                    text: this.$capitalize(this.tryouts[this.selectedTryout].participantPronoun)+"Number",
                    align: 'start',
                    sortable: true,
                    value: 'participantNumber',
                },
                
                //{
                 //   text: 'Profile Image',
                 //   align: 'start',
                 //   sortable: false,
                 //   value: 'profileImage'
                //},
                {
                    text: 'First Name',
                    align: 'start',
                    value: 'firstName'
                },
                {
                    text: 'Last Name',
                    align: 'start',
                    value: 'lastName',
                },
                
            ],
            */
            rules: {
                name: v => {
                    const pattern=/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
                    return (!pattern.test(v)) || 'Name must not contain any special characters'
                },
                length: v => {
                    return v.length >1 || 'Length must be greater than 2 characters'
                },
                number: v => {
                    const pattern=/^\+?\d+$/
                    return pattern.test(v) || 'Not a number. Must have no letters or special characters'
                },
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },

        }
    }
}

</script>

<style scoped>
.strikeout {
    color: red;
    text-decoration-line: line-through;
}

.v-data-table {
    line-height: 1;
    max-width: 100%;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 8px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 50px;
    padding: 0 0px;
}
</style>