import {attendanceService} from '../_services/attendance.service'
import Vue from 'vue'

const dataService = attendanceService

function initialiseState(){
    return {
        status: {},
        data: {},
        list: [],
        perSessionLists: {},
        error: null,
        cache: {},
        meta: {},
        internal: {}
    }
}

const state = initialiseState()

function addToLists(data) {
    // Add it to the generic lists first

    const id = data.id
    state.list.push(id)

    // Add it to the data dictionary
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
        delete: false,
    })

    // Now add it to the session attendance lists

    var list={}
    if(Object.prototype.hasOwnProperty.call(state.perSessionLists, data.sessionId)){
        list = state.perSessionLists[data.sessionId]
    }
    list[data.participantId] = id
    Vue.set(state.perSessionLists, data.sessionId, list)

    // Add one to the counterList
    list = {}
    if(Object.prototype.hasOwnProperty.call(state.perSessionLists, 0)){
        list = state.perSessionLists[0]
    }
    if(!Object.prototype.hasOwnProperty.call(list, data.participantId)){
        list[data.participantId]=0
    }
    if(data.attended>0) list[data.participantId]++
    Vue.set(state.perSessionLists, 0, list)

}

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    /*
    async "SOCKET_NewParticipant"({commit}, data){
        console.log('Data')
        console.log(data)
        
        console.log('Saw a new participant added - adding an attendance record')
        commit('loadRequest')
        const response=await dataService.findAllForEvent(data.eventId)
        commit('loadSuccess', response)
    },
    */

    async findAllForEvent({commit}, payload) {
        const {eventId} = payload
        commit('loadRequest')

        try{
            const response = await dataService.findAllForEvent(eventId)
            commit('loadSuccess', response)
        } catch(error) {
            commit('loadFailure', error)

        }
    },
    async quietFindAllForEvent({commit}, payload) {
        const {eventId} = payload
        commit('quietLoadRequest')

        try{ 
            const response=await dataService.findAllForEvent(eventId)
            commit('loadSuccess', response)
        } catch(error){
            commit('loadFailure', error)
        }
    },
    async update({commit}, payload){
        commit('updateRequest', payload)

        try{
            const response=await dataService.update(payload)
            commit('updateSuccess', response)
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async localUpdate({commit}, payload){
        commit('updateLocalAttendance', payload)
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    }
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
    loadRequest(state) {
        state.status={loading:true}
        state.error=null
    },
    quietLoadRequest(state) {
        state.status={}
        state.error=null
    },
    loadSuccess(state, data){
        state.status={}
        state.data={}
        state.list=[]

        state.perSessionLists={}
        for(var idx in data.attendanceRecords) {
            addToLists(data.attendanceRecords[idx])
        }
    },
    loadFailure(state, error){
        state.status={}
        state.error=error
    },

    updateRequest(state){
        state.error=null
    },
    updateSuccess(state, response) {
        state.internal = {response}
    },
    updateFailure(error) {
        state.error=error
        state.status={}
    },

    updateLocalAttendance(state, payload){
        const {attendanceId, attended, absenceReason} = payload
        const attendanceData = state.data[attendanceId]
        attendanceData.attended = attended
        attendanceData.absenceReason = absenceReason
        Vue.set(state.data, attendanceId, attendanceData)

        var list={}
        if(Object.prototype.hasOwnProperty.call(state.perSessionLists, attendanceData.sessionId)){
            list = state.perSessionLists[attendanceData.sessionId]
        }
        list[attendanceData.participantId] = attendanceData.id
        Vue.set(state.perSessionLists, attendanceData.sessionId, list)

        // NOw update the count of sessions attended...
        const keys = Object.keys(state.perSessionLists)
        var count=0
        for(var idx in keys){
            if(keys[idx]!=='0'){
                const attId = state.perSessionLists[keys[idx]][attendanceData.participantId]
                if(state.data[attId].attended > 0){
                    count++
                }
            }
        }
        state.perSessionLists[0][attendanceData.participantId]=count
    },
    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete"
        const {field, id, value} = payload

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            state.data[id][field]=value
        }
    },
}

export const attendanceStore = {
    namespaced: true,
    state,
    actions,
    mutations
}