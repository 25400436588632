<template>
    <loading v-if="loading"/>
    <div v-else-if="eventAllows(['admin', 'selector', 'owner', 'support'])" class="root-page">
        <!--
        <v-app-bar height="32px" color="var(--cs1-lev2)" style="padding-left:0px;margin-left:0px;">
                        <span class="third-chev" @click="nothing">Tryout Homepage </span>
        </v-app-bar>
    -->
                    <v-dialog v-model="cloneDialog" persistent max-width="60%" activator="parent" >
                        <ValidationObserver ref="form" v-slot="{handleSubmit}">
                            <form @submit.prevent="handleSubmit(clone)">
                                <v-card>
                                    <v-card-title>
                                        <h3>Create a new template</h3>
                                    </v-card-title>
                                    <v-card-text>
                                        <ValidationProvider name="Name" rules="required" v-slot="{errors}">
                                            <v-text-field label="Title" v-model="newTitle"/>
                                            <div class="form-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                                        <v-btn color="blue darken-1" text type="submit">Clone</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </form>
                        </ValidationObserver>
                    </v-dialog>

        <!--
        | Mobile view
        -->
        <div v-responsive.xs.sm>
            <v-row>
                <v-col cols="8">
                    <div class="pane-heading mb-5">Tryout Homepage</div>
                </v-col>
                <v-col xs="4" sm="4" md="4" lg="4" xl="4" style="text-align:right">
                    <v-btn v-if="allowClone" color="primary" x-small @click="cloneDialog=true">Clone to a new Tryout </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="allowSettings" cols="3" class="mt-0 mb-0">
                    <div class="settings-button" @click="selectPane('TryoutSettings')">
                        <v-icon large color="white">mdi-cog-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Setup</div>
                    </div>
                </v-col>
                <v-col v-if="allowCapture" cols="3" class="mt-0 mb-0">
                    <div class="capturedata-button" @click="selectPane('TryoutCollectData')">
                        <v-icon large color="white">mdi-clipboard-edit-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Collect Data</div>
                    </div>
                </v-col>
                <v-col v-if="allowParticipantList" cols="3" class="mt-0 mb-0">
                    <div class="participantlist-button" @click="selectPane('ParticipantList')">
                        <v-icon large color="white">mdi-account-group-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">{{ participantPronoun }} List</div>
                    </div>
                </v-col>
                <v-col v-if="allowSelectTeams" cols="3" class="mt-0 mb-0">
                    <div class="teamselect-button" @click="selectPane('SelectTeams')">
                        <v-icon large color="white">mdi-account-multiple-check</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Select Teams</div>
                    </div>
                </v-col>
                <v-col v-if="allowJournal" cols="3" class="mt-0 mb-0">
                    <div class="journal-button" @click="selectPane('JournalHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Journal Library</div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" style="margin-top:0">
                    <BoundedBox sidePadding="0px" sideMargin="0px">
                        <span slot="title">Key Stats</span>
                        <div slot="content">
                            <v-row class="stat-row" v-for="statItem,n in statData" :key="n">
                                <v-col cols="8" class="stat-header">
                                    {{ statItem.name }}
                                </v-col>
                                <v-col class="stat-value">
                                    {{ statItem.value }}
                                </v-col>
                            </v-row>
                        </div>
                    </BoundedBox>
                </v-col>
                <v-col cols="12">
                    <div class="description-box">
                        <div class="description-title">Tryout Readiness</div>
                        <div>
                            <vue-speedometer v-responsive.sm.xs 
                                :width="150"
                                :height="100"
                                :minValue="0"

                                :maxValue="100"
                                :maxSegmentLabels="0"
                                :customSegmentStops="[0,50,75,100]"
                                :customSegmentLabels='[
                                    {
                                        text: "Not Ready",
                                        position: "OUTSIDE",
                                        color: "grey"
                                    },
                                    {
                                        text: "Almost",
                                        position: "OUTSIDE",
                                        color: "grey"
                                    },
                                    {
                                        text: "Ready",
                                        position: "OUTSIDE",
                                        color: "grey"
                                    }
                                ]'
                                :segmentColors="['tomato','gold','limegreen']"
                                :value="completionRate"
                                :needleHeightRatio="0.7"
                                :ringWidth="30"
                                currentValueText=""
                                style="height:100%"
                            />

                        </div>
                        <div class="indicator-title">
                            <v-tooltip open-on-click :open-on-focus="false" :open-on-hover="false" close-on-click bottom v-if="completionRate<75" color="green darken-3">

                                <template v-slot:activator="{on, attrs}">
                                    <v-btn icon small v-bind="attrs" v-on="on">
                                        <v-icon small color="orange">mdi-information</v-icon>
                                    </v-btn>
                                </template>
                                <div>
                                    <div class="hint-heading">Yet to be set:</div>
                                    <ul>
                                        <li v-for="line in missing" :key="line">
                                            {{ line }}
                                        </li>
                                    </ul>
                                    <v-divider color="white"/>
                                    <div class="hint-footer">
                                        Select "Tryout Settings" from the menu to set these
                                    </div>
                                </div>
                            </v-tooltip>
                            <v-tooltip open-on-click :open-on-focus="false" :open-on-hover="false" close-on-click bottom v-else color="green darken-3">

                                <template v-slot:activator="{on, attrs}">
                                    <v-btn icon small v-bind="attrs" v-on="on">
                                        <v-icon small color="green">mdi-check-circle</v-icon>
                                    </v-btn>
                                </template>
                                <div>
                                    <div class="hint-heading">Yet to be set:</div>
                                    <ul>
                                        <li v-for="line in missing" :key="line">
                                            {{ line }}
                                        </li>
                                    </ul>
                                    <v-divider color="white"/>
                                    <div class="hint-footer">
                                        Select "Tryout Settings" from the menu to set these
                                    </div>
                                </div>
                            </v-tooltip>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="8" lg=8 xl="8" style="margin-top:0">
                    <div style="margin-top:0px;text-align:left">
                        <v-row>
                            <v-col cols="6" >
                                <span class="info-label">Starts: </span>{{ moment(tryouts[selectedTryout].startDate).format('ddd Do MMM, YYYY') }}
                            </v-col>
                            <v-col cols="6">
                                <span class="info-label"> Ends:</span> {{ moment(tryouts[selectedTryout].endDate).format('ddd Do MMM, YYYY') }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <p><span class="info-label">Sessions</span></p>
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td>
                                                Date
                                            </td>
                                            <td>
                                                Venue
                                            </td>
                                            <td>
                                                Time
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(id, idx) in sessionList" :key="id">

                                            <td :class="{strike: oldDate(sessions[id].startDateTime)}"> {{ idx+1 }}</td>
                                            <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('ddd Do MMM, YYYY')  }}</td>
                                            <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ sessions[id].venue }}</td>
                                            <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('HH:mm') }} - {{ moment(sessions[id].endDateTime).format('HH:mm') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

        </div>

        <!--
        | Tablet/Desktop View
        -->
        <div v-responsive.md.lg.xl>
            <v-row>
                <v-col cols="4">
                    <div class="pane-heading">Tryout Homepage</div>
                </v-col>
                <v-spacer/>
                <v-col xs="4" sm="4" md="4" lg="4" xl="4" style="text-align:right">
                    <v-btn v-if="allowClone" color="primary" x-small @click="cloneDialog=true">Clone to a new Tryout </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1">
                    <div v-if="allowSettings" class="settings-button mb-5" @click="selectPane('TryoutSettings')">
                        <v-icon large color="white">mdi-cog-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Setup</div>
                    </div>
                    <div v-if="allowCapture" class="capturedata-button mb-5" @click="selectPane('TryoutCollectData')">
                        <v-icon large color="white">mdi-clipboard-edit-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Collect Data</div>
                    </div>
                    <div v-if="allowParticipantList" class="participantlist-button mb-5" @click="selectPane('ParticipantList')">
                        <v-icon large color="white">mdi-account-group-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">{{ participantPronoun }} List</div>
                    </div>
                    <div v-if="allowSelectTeams" class="teamselect-button mb-5" @click="selectPane('SelectTeams')">
                        <v-icon large color="white">mdi-account-multiple-check</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Select Teams</div>
                    </div>
                    <div v-if="allowJournal" class="journal-button mb-5" @click="selectPane('JournalHome')">
                        <v-icon large color="white">mdi-notebook-outline</v-icon>
                        <div style="font-size:small; color:white;" class="text-center">Journal Library</div>
                    </div>

                </v-col>
                <v-col cols="8" class="mt-0">
                    <div style="margin-top:0px;text-align:left">
                        <v-row>
                            <v-col cols="6" >
                                <span class="info-label">Starts: </span>{{ moment(tryouts[selectedTryout].startDate).format('ddd Do MMM, YYYY') }}
                            </v-col>
                            <v-col cols="6">
                                <span class="info-label"> Ends:</span> {{ moment(tryouts[selectedTryout].endDate).format('ddd Do MMM, YYYY') }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <BoundedBox sidePadding="0px" sideMargin="0px" bottomPadding="5px">
                                <span slot="title">Sessions</span>
                                <div slot="content">
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        Name
                                                    </td>
                                                    <td>
                                                        Date
                                                    </td>
                                                    <td>
                                                        Venue
                                                    </td>
                                                    <td>
                                                        Time
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(id, idx) in sessionList" :key="id">

                                                    <td :class="{strike: oldDate(sessions[id].startDateTime)}"> {{ idx+1 }}</td>
                                                    <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ sessions[id].title }}</td>
                                                    <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('ddd Do MMM, YYYY')  }}</td>
                                                    <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ sessions[id].venue }}</td>
                                                    <td :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('HH:mm') }} - {{ moment(sessions[id].endDateTime).format('HH:mm') }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </BoundedBox>
                        </v-row>
                    </div>

                </v-col>
                <v-col cols="3" class="mt-0">
                    <div class="description-box">
                        <div>
                            <div class="description-title">Tryout Readiness</div>
                            <vue-speedometer 
                                :width="200"
                                :height="100"
                                :minValue="0"

                                :maxValue="100"
                                :customSegmentLabels='[
                                    {
                                        text: "Not Ready",
                                        position: "INSIDE",
                                        color: "white"
                                    },
                                    {
                                        text: "Almost",
                                        position: "INSIDE",
                                        color: "black"
                                    },
                                    {
                                        text: "Ready",
                                        position: "INSIDE",
                                        color: "black"
                                    }
                                ]'
                                :customSegmentStops="[0,50,75,100]"
                                :segmentColors="['tomato','gold','limegreen']"
                                :value="completionRate"
                                :needleHeightRatio="0.7"
                                labelFontSize="10px"
                                :ringWidth="30"
                                currentValueText=""
                            />
                        </div>
                    </div>
                    <div class="indicator-title">
                        <v-tooltip open-on-click :open-on-focus="false" :open-on-hover="false" close-on-click bottom v-if="completionRate<75" color="green darken-3">

                            <template v-slot:activator="{on, attrs}">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon small color="orange">mdi-information</v-icon>
                                </v-btn>
                            </template>
                            <div>
                                <div class="hint-heading">Yet to be set:</div>
                                <ul>
                                    <li v-for="line in missing" :key="line">
                                        {{ line }}
                                    </li>
                                </ul>
                                <v-divider color="white"/>
                                <div class="hint-footer">
                                    Select "Tryout Settings" from the menu to set these
                                </div>
                            </div>
                        </v-tooltip>
                        <v-tooltip open-on-click :open-on-focus="false" :open-on-hover="false" close-on-click bottom v-else color="green darken-3">

                            <template v-slot:activator="{on, attrs}">
                                <v-btn icon small v-bind="attrs" v-on="on">
                                    <v-icon small color="green">mdi-check-circle</v-icon>
                                </v-btn>
                            </template>
                            <div>
                                <div class="hint-heading">Yet to be set:</div>
                                <ul>
                                    <li v-for="line in missing" :key="line">
                                        {{ line }}
                                    </li>
                                </ul>
                                <v-divider color="white"/>
                                <div class="hint-footer">
                                    Select "Tryout Settings" from the menu to set these
                                </div>
                            </div>
                        </v-tooltip>
                    </div>
                    <div>
                        <BoundedBox sidePadding="0px" sideMargin="0px">
                            <span slot="title">Key Stats</span>
                            <div slot="content">
                                <v-row class="stat-row" v-for="statItem,n in statData" :key="n">
                                    <v-col cols="8" class="stat-header">
                                        {{ statItem.name }}
                                    </v-col>
                                    <v-col class="stat-value">
                                        {{ statItem.value }}
                                    </v-col>
                                </v-row>
                            </div>
                        </BoundedBox>              
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>

import {licenseAllows} from '@/_helpers/license'
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import Loading from '@/components/loading'
import { mapState, mapActions } from 'vuex';
import VueSpeedometer from 'vue-speedometer'
import BoundedBox from '@/components/BoundedBox'

export default {
    props: [],
    components: {
        Loading, 
        VueSpeedometer,
        BoundedBox,
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            tryouts             : state => state.tryoutStore.data,
            tryoutList          : state => state.tryoutStore.list,
            tryoutStatus        : state => state.tryoutStore.status,
            tryoutError         : state => state.tryoutStore.error,

            sessions            : state => state.sessionStore.data,
            sessionList         : state => state.sessionStore.list,
            sessionStatus       : state => state.sessionStore.status,

            appquestionList     : state => state.appquestionStore.list,
            appquestionStatus   : state => state.appquestionStore.status, 

            criterionList       : state => state.criterionStore.list,
            criterionStatus     : state => state.criterionStore.status,

            attributeList       : state => state.attributeStore.list,
            attributeStatus     : state => state.attributeStore.status,

            teamList            : state => state.teamStore.list,
            teamStatus          : state => state.teamStore.status,


            selectedTryout      : state => state.navigatorStore.selectedTryout,
            selectedSeason      : state => state.navigatorStore.selectedSeason,

        }),
        loading(){
            return false
                || this.teamStatus.loading
                || this.sessionStatus.loading
                || this.appquestionStatus.loading
                || this.criterionStatus.loading
                || this.attributeStatus.loading
                || this.tryoutStatus.loading
                || !this.dataReady
        },
        participantPronoun(){
            return this.$capitalize(this.tryouts[this.selectedTryout].participantPronoun)
        },
        completionRate() {
            return Math.round(this.completion/26*100)
        },
        sessionsToGo(){
            var result=0;
            for(var idx in this.sessionList){
                const session = this.sessions[this.sessionList[idx]]
                if(!this.oldDate(session.endDateTime))
                    result+=1
            }
            return result
        },
        statHeaders(){
            return [
                {
                    text:'',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                    cellClass: 'stat-header'
                },
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'value'
                }
            ]
        },
         allowClone(){
            return  licenseAllows('cloneTryouts') && (this.tryouts[this.selectedTryout].admin || this.tryouts[this.selectedTryout].owner)
        },
        statData() {
            return [
                { 
                    name: 'Registered Athletes',
                    value: this.tryouts[this.selectedTryout].participantCount
                },
                {
                    name: 'Registered Selectors',
                    value: this.tryouts[this.selectedTryout].selectorCount
                },
                {
                    name: 'Sessions yet to complete',
                    value: this.sessionsToGo
                }
            ]    
        },
        allowSettings(){
            if(  this.tryouts[this.selectedTryout].admin
              || this.tryouts[this.selectedTryout].owner
              || this.tryouts[this.selectedTryout].headcoach
            ) return true;
            return false
        },
        allowJournal(){
            if(  this.tryouts[this.selectedTryout].admin
              || this.tryouts[this.selectedTryout].owner
              || this.tryouts[this.selectedTryout].headcoach
              || this.tryouts[this.selectedTryout].assistantcoach
            ) return true
            return false
        },
        allowCapture(){
            if(  this.tryouts[this.selectedTryout].admin
              || this.tryouts[this.selectedTryout].owner
              || this.tryouts[this.selectedTryout].headcoach
              || this.tryouts[this.selectedTryout].assistantcoach
              || this.tryouts[this.selectedTryout].selector
        ) return true
            return false
        },
        allowParticipantList(){
            if(  this.tryouts[this.selectedTryout].admin
              || this.tryouts[this.selectedTryout].owner
              || this.tryouts[this.selectedTryout].headcoach
              || this.tryouts[this.selectedTryout].assistantcoach
              || this.tryouts[this.selectedTryout].selector
            ) return true
            return false
        },
        allowSelectTeams(){
            if(  this.tryouts[this.selectedTryout].admin
              || this.tryouts[this.selectedTryout].owner
              || this.tryouts[this.selectedTryout].headcoach
              || this.tryouts[this.selectedTryout].assistantcoach
            ) return true
            return false
        }
        
    },
    methods: {
        licenseAllows,
        ...mapActions('sessionStore', [
            'loadAllSessionsForEvent',
        ]),
        ...mapActions('appquestionStore', {
            findAllQuestionsForEvent: 'loadAllQuestionsForEvent',
        }),
        ...mapActions('criterionStore', {
            findAllCriteriaForEvent: 'findAllForEvent',
        }),
        ...mapActions('teamStore', {
            loadTeamsForEvent: 'findAllForEvent',
        }),
        ...mapActions('attributeStore', {
            findAllAttributes: 'findAllForEvent',
        }),
        ...mapActions('tryoutStore', {
            getAllTryoutsForSeason: 'findAllForSeason',
            cloneTryout: 'cloneTryout',
        }),
        ...mapActions('roleStore',[
            'loadAllRolesForEvent',
        ]),
        ...mapActions('navigatorStore', {
            selectPane: 'selectPane',
        }),
        nothing(){

        },

        oldDate(date){
            return new Date(date) < new Date()
        },
        closeDialog(){
            this.newTitle=''
            this.cloneDialog=false
        },
        async clone(){
            try{

                await this.cloneTryout({
                    name: this.newTitle,
                    eventId: this.selectedTryout,
                    seasonId: this.selectedSeason,
                })
                this.$toast.success('This tryout has been cloned into a new tryout for this season. Go back to the season homepage to customise it.')
            } catch(payload) {
                this.$toast.error('There was a problem cloning this tryout. If this problem persists, please contact CoachSight support for assistance')
            }
            this.newTitle=''
            this.cloneDialog=false
        }
    },
    async created() {

        this.dataReady=false
        if(this.tryoutList.length === 0)
            await this.getAllTryoutsForSeason(this.selectedSeason)
        await this.loadAllSessionsForEvent({eventId: this.selectedTryout})
        await this.findAllQuestionsForEvent({eventId: this.selectedTryout})
        await this.findAllCriteriaForEvent(this.selectedTryout)
        
        //await this.loadTeamsForEvent({eventId: this.selectedTryout})
        await this.findAllAttributes({eventId: this.selectedTryout})
        await this.loadAllRolesForEvent({eventId: this.selectedTryout}) 
         // Measure the completion/competition readiness of the tryout

        this.completion=0
        this.missing=[]

        const thisTryout = this.tryouts[this.selectedTryout]

        if(thisTryout.title) this.completion+=1; else this.missing.push('A title')
        if(thisTryout.description) this.completion+=1; else this.missing.push('A description')
        if(thisTryout.startDate) this.completion +=1; else this.missing.push('A start date')
        if(thisTryout.endDate) this.completion +=1; else this.missing.push('An end date')
        if(thisTryout.location) this.completion +=1; else this.missing.push('A location for the tryout')
        if(thisTryout.contactEmail) this.completion +=2; else this.missing.push('A contact email for the tryout organiser')
        if(thisTryout.contactName) this.completion +=2; else this.missing.push('A contact name for the tryout organiser')
        if(thisTryout.contactPhone) this.completion +=2; else this.missing.push('A contact phone number for the tryout organiser')
        if(thisTryout.selectorCount >0) this.completion +=4; else this.missing.push('Selection Staff')
        if(this.appquestionList.length>0) this.completion +=2; else this.missing.push('Application Questions for participants')
        if(this.appquestionList.length>2) this.completion +=2; else this.missing.push('Add more questions to help the selection process')
        if(this.criterionList.length>0) this.completion += 2; else this.missing.push('Criteria for assessment of participants')
        if(this.criterionList.length>2) this.completion += 2; else this.missing.push('Add more criteria to help classify participants')
        if(this.attributeList.length >0) this.completion +=1; else this.missing.push('Participant attributes for measurement (e.g. jump height)')
        if(this.teamList.length>0) this.completion += 2; else this.missing.push('Teams for selection into from this tryout')

        this.dataReady=true    
    },
    data() {
        return {
            dataReady: false,
            completion: 0,
            missing: [],
            cloneDialog: false,
            newTitle: '',

        }
    }
}</script>

<style >
    .indicator-box {
        border-radius: 10px;
        border-style: solid;
        border-color:blueviolet
    }
    .description-box {
        border-radius: 10px;
        border-width: 1px;
        border-color: grey;
        border-style: solid;
        margin-top: 10px;
    }
    .description-title {
        text-align: center;
        font-size: small;
        color:grey;
        margin-top: -10px;
        margin-left:20px;
        background-color: white;
        width: 100px;
    }
    .indicator-title {
        font-weight: 600;
        text-align: center;
    }

    .title {
        font-weight: 600;
        font-size: small;
        text-align: left;
    }

    .completion-hint {
        background-color:honeydew;
    }

    .hint-heading {
        font-weight:600;
        text-align: left;
        padding-bottom: 10px;
    }

    .hint-footer {
        padding-top: 10px;
        text-align: left;
    }

    .description {
        text-align: left;
        padding:10px;
    }
    .stat-row{
        padding:0px;
        margin: 0px;
    }
    .stat-header {
        font-weight: 400;
        color:var(--cs1-lev1);
        text-align: left;
        padding:0px 0px 0px 10px;
        font-size: small;
        font-family: varela round;

    }
    .stat-value{
        padding:0px;
        font-size: small;
        font-family: varela round;
    }
    .info-label {
        font-weight: 600;
        padding-right: 10px;
    }
.settings-button{
    background-color: var(--cs2-lev1);
    display:flex;
    flex-flow:column;
    padding:10px;
    border-radius:20px;
    min-height: 100%;
    padding:5px;
}
.settings-button:hover{
    filter: brightness(85%);
}
.capturedata-button{
    background-color: var(--cs2-lev1);
    display:flex;
    flex-flow:column;
    padding:10px;
    border-radius:20px;
    min-height: 100%;
    padding:5px;
}
.capturedata-button:hover{
    filter: brightness(85%);
}
.teamselect-button{
    background-color: var(--cs2-lev1);
    display:flex;
    flex-flow:column;
    padding:10px;
    border-radius:20px;
    min-height: 100%;
    padding:5px;
}
.teamselect-button:hover{
    filter: brightness(85%);
}
.journal-button{
    background-color: var(--cs2-lev1);
    display:flex;
    flex-flow:column;
    padding:10px;
    border-radius:20px;
    min-height: 100%;
    padding:5px;
}
.journal-button:hover{
    filter: brightness(85%);
}

.participantlist-button{
    background-color: var(--cs2-lev1);
    display:flex;
    flex-flow:column;
    border-radius:20px;
    min-height: 100%;
    padding:5px;
}
.participantlist-button:hover{
    filter: brightness(85%);
}

</style>